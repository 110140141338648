import React from 'react';
import TelcoIoTImage from '../../assets/images/Torre5G.webp'; // Immagine sfondo Hero
import MonitoringImage from '../../assets/images/Industry.webp'; // Immagine principale del blocco
import GTechImage from '../../assets/images/GTech.jpg'; // Immagine principale del blocco
import SensorLockImage from '../../assets/images/SensorLock.webp'; // Immagine principale del blocco

const TelecomIot = () => {
  return (
    <>
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${TelcoIoTImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-700/70"></div>

        {/* Contenuto Hero */}
        <div className="relative z-10 text-white px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Connettività e Innovazione:
          </h1>
          <h1 className="text-4xl md:text-6xl font-bold mb-16">
            Soluzioni Telco & IoT per il Futuro
          </h1>
          <p className="text-lg md:text-2xl italic text-gray-100 mb-20">
            Offriamo soluzioni avanzate di telecomunicazione e tecnologie IoT per ottimizzare comunicazioni aziendali, monitoraggio in tempo reale e gestione smart dei processi.
          </p>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Richiedi una Valutazione Gratuita
          </a>
        </div>
      </section>

      {/* Blocco 2: Telecomunicazioni Avanzate */}
      <section className="py-24 bg-gray-900 text-secondary">
        <div className="max-w-7xl mx-auto px-6 md:px-12">
          {/* Titolo e Descrizione */}
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-yellow-500 mb-8">
              Soluzioni di Telecomunicazione Affidabili e Performanti
            </h2>
            <p className="text-xl text-gray-300 leading-relaxed mb-12">
              Garantiamo connettività aziendale stabile e sicura con soluzioni di telecomunicazione avanzate, pensate per reti locali, VoIP e 5G.
            </p>
          </div>

          {/* Griglia dei Punti Chiave */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center mb-16">
            {/* Card 1: Reti VoIP */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-yellow-500">
              <div className="mb-4">
                <span className="text-4xl text-yellow-500">📞</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Reti VoIP</h3>
              <p className="text-lg text-gray-300">
                Comunicazioni vocali performanti e riduzione dei costi aziendali.
              </p>
            </div>

            {/* Card 2: Connettività 5G */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-yellow-500">
              <div className="mb-4">
                <span className="text-4xl text-yellow-500">📡</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Connettività 5G</h3>
              <p className="text-lg text-gray-300">
                Connessioni ad alta velocità per applicazioni industriali.
              </p>
            </div>

            {/* Card 3: Ottimizzazione della Banda */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-yellow-500">
              <div className="mb-4">
                <span className="text-4xl text-yellow-500">📶</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Ottimizzazione della Banda</h3>
              <p className="text-lg text-gray-300">
                Gestione intelligente del traffico di rete per massime prestazioni.
              </p>
            </div>
          </div>

          {/* CTA Locale */}
          <div className="text-center mt-12">
            <a
              href="/contact"
              className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Richiedi una Valutazione Gratuita
            </a>
          </div>
        </div>
      </section>

      {/* Blocco 3: Soluzioni IoT per l’Industria */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Gestione Intelligente dei Processi con l’Internet of Things
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Implementiamo soluzioni IoT su misura per monitorare, controllare e ottimizzare processi industriali e aziendali in tempo reale.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Monitoraggio Smart:</strong> Sensori IoT per rilevare parametri critici come temperatura, consumi energetici e prestazioni operative.</li>
          <li><strong>Automazione dei Processi:</strong> Controllo remoto di dispositivi e macchinari per ridurre gli interventi manuali.</li>
          <li><strong>Dashboard in Tempo Reale:</strong> Analisi e visualizzazione dei dati raccolti per decisioni strategiche.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${MonitoringImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

{/* Blocco 4: Monitoraggio e Tracciamento IoT */}
<section className="py-24 bg-gray-900 text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Tracciamento Avanzato per Logistica e Asset Management
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Le nostre soluzioni IoT ti permettono di monitorare e gestire in tempo reale asset critici, garantendo una visibilità completa e ottimizzando i costi operativi.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Monitoraggio degli Asset:</strong> Tracciamento in tempo reale di beni ed infrastrutture critiche.</li>
          <li><strong>Manutenzione Predittiva:</strong> Analisi avanzata per prevenire guasti e ottimizzare le operazioni di manutenzione.</li>
          <li><strong>Monitoraggio Ambientale:</strong> Sensori per rilevare condizioni come temperatura, umidità e vibrazioni.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${GTechImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

{/* Blocco 5: Sicurezza e Gestione dei Dati IoT */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Protezione e Analisi dei Dati IoT
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        La crescita dei dispositivi connessi richiede soluzioni avanzate per proteggere e gestire i dati. Offriamo sicurezza totale e strumenti di analisi predittiva.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Sicurezza dei Dati IoT:</strong> Protezione delle comunicazioni tra dispositivi con crittografia avanzata.</li>
          <li><strong>Analisi Predittiva:</strong> Identificazione di pattern e anomalie per prevenire rischi.</li>
          <li><strong>Integrazione Cloud:</strong> Archiviazione sicura e accesso ai dati da qualsiasi dispositivo.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${SensorLockImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

    {/* Blocco 6: CTA Finale */}
    <section className="py-24 bg-[#070918] text-secondary text-center">
      <div className="max-w-5xl mx-auto px-6 md:px-12">
        {/* Titolo */}
        <h3 className="text-4xl md:text-5xl font-bold mb-8">
          Pronto a Innovare con le Soluzioni Telco e IoT di GalileoSat?
        </h3>
        {/* Testo */}
        <p className="text-xl leading-relaxed mb-12">
          Contattaci per scoprire come possiamo migliorare la tua connettività, ottimizzare i processi e garantire il controllo completo delle tue risorse.
        </p>
        {/* Bottone CTA */}
        <a
          href="/contact"
          className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
        >
          Richiedi una Consulenza Gratuita
        </a>
      </div>
      {/* Linea Separatrice */}
      <div className="w-full h-[1px] bg-yellow-500 mt-20"></div>
    </section>
    </>
  );
};

export default TelecomIot;
