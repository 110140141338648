import React from 'react';
import { useNavigate } from 'react-router-dom';

const AiActGuide = () => {
  const navigate = useNavigate(); // Hook per navigare tra le pagine
  return (
    <div className="min-h-screen bg-[#070918] py-12">
      {/* Titolo dell'articolo */}
      <h1 className="text-4xl font-bold text-center text-white mb-6">
        AI Act: cosa cambierà per le aziende nel 2025?
      </h1>
      {/* Data di pubblicazione */}
      <p className="text-center text-gray-400 mb-12">
        Pubblicato il 5 Dicembre 2024
      </p>
      {/* Contenuto dell'articolo */}
      <div className="max-w-4xl mx-auto px-6 article-content leading-relaxed">
        <p>
          Entrato in vigore il 1° agosto 2024, l’AI Act rappresenta un passo storico nella regolamentazione
          dell’Intelligenza Artificiale (IA) in Europa. Questo regolamento mira a garantire un utilizzo etico,
          responsabile e trasparente dell’IA, proteggendo i diritti fondamentali delle persone e incentivando
          l’innovazione tecnologica.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Cosa prevede l’AI Act?</h2>
        <p>
          L’AI Act stabilisce norme chiare per lo sviluppo e l’utilizzo dell’IA, classificando i sistemi in base
          al rischio che rappresentano per la sicurezza e i diritti delle persone. Questa classificazione consente
          di adottare misure proporzionate ai potenziali impatti, promuovendo al contempo la fiducia nell’utilizzo
          di queste tecnologie.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Classificazione dei rischi:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Rischio minimo:</strong> Sistemi di IA con rischi trascurabili per gli utenti, come filtri
            antispam o giochi basati su IA.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Rischio limitato:</strong> Tecnologie che richiedono trasparenza, ad esempio chatbot che
            devono informare l’utente di stare interagendo con un sistema di IA.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Rischio elevato:</strong> Applicazioni in settori critici come la sanità o l’istruzione,
            soggette a rigorosi requisiti di sicurezza.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Rischio inaccettabile:</strong> Pratiche vietate, come la sorveglianza biometrica di massa
            o l’uso di IA per manipolazione comportamentale.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">
          Obblighi principali per gli sviluppatori e gli utenti:
        </h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Trasparenza:</strong> Gli utenti devono essere informati quando interagiscono con un sistema
            di IA. Inoltre, i contenuti generati da IA, come i deepfake, devono essere chiaramente identificati.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Sicurezza dei dati:</strong> I sistemi di IA devono rispettare standard elevati di protezione
            dei dati personali, in linea con il GDPR.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Audit e certificazioni:</strong> Le applicazioni di IA classificate come ad alto rischio devono
            essere sottoposte a controlli regolari per garantire conformità alle normative.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Implicazioni per il futuro:</h2>
        <p>
          L’AI Act non è solo una misura regolatoria, ma anche un invito a sviluppare tecnologie che rispettino
          valori etici e diritti umani. Questa normativa fornisce una base solida per la crescita di un mercato
          europeo dell’IA, competitivo e responsabile.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Conclusione:</h2>
        <p>
          Con l’AI Act, l’Unione Europea si pone all’avanguardia nella regolamentazione dell’Intelligenza
          Artificiale, dimostrando che è possibile bilanciare innovazione tecnologica e protezione dei diritti
          fondamentali.
        </p>
      </div>
    {/* Pulsante per tornare alla pagina Blog */}
    <div className="text-center mt-12">
        <button
          onClick={() => navigate('/blog')}
          className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
        >
          Torna al Blog
        </button>
      </div>
    </div>
  );
};

export default AiActGuide;
