import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ScrollToTop from './components/common/ScrollToTop';
import './output.css';
import CybersecurityGuide from './pages/Blog/CybersecurityGuide';
import VPNGuide from './pages/Blog/VPNGuide'; 
import GDPRGuide from './pages/Blog/GDPRGuide';
import AiActGuide from './pages/Blog/AiActGuide';
import LoadBalancingGuide from './pages/Blog/LoadBalancingGuide';
import VlanGuide from './pages/Blog/VlanGuide';
import GNSSCoolGuide from './pages/Blog/GNSSCoolGuide';
import GNSSLogisticGuide from './pages/Blog/GNSSLogisticGuide';
import AICybersecurityGuide from './pages/Blog/AICybersecurityGuide';
import AutomazioneGuide from './pages/Blog/AutomazioneGuide';
import CybersecurityAwarenessGuide from './pages/Blog/CybersecurityAwarenessGuide';
import GDPRmanagerGuide from './pages/Blog/GDPRmanagerGuide';
import UseCaseGuide from './pages/Blog/UseCaseGuide';
import ICTGuide from './pages/Blog/ICTGuide';

// Componenti comuni
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTopButton from './components/common/ScrollToTopButton';
import CookieBanner from './components/common/CookieBanner';

// Pagine principali
import Home from './pages/Home';
import Blog from './pages/Blog';
import About from './pages/About';
import Contact from './pages/Contact';
import CookiePolicy from './pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// Pagine Servizi
import Servizi from './pages/Servizi/Servizi';
import ITNetwork from './pages/Servizi/ITNetwork';
import Cybersecurity from './pages/Servizi/Cybersecurity';
import TelecomIot from './pages/Servizi/TelecomIot';
import Satcom from './pages/Servizi/Satcom';
import Consulting from './pages/Servizi/Consulting';

// Pagine Soluzioni
import Soluzioni from './pages/Soluzioni/Soluzioni';
import Networking from './pages/Soluzioni/Networking';
import Security from './pages/Soluzioni/Security';
import GNSS from './pages/Soluzioni/GNSS';
import Programmi from './pages/Soluzioni/Programmi';

const App = () => {
  const location = useLocation(); // Manteniamo useLocation per gestire transizioni.

  return (
    <>
      <ScrollToTop />
      <Header />
      <CookieBanner />
      <div className="content">
  <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cookie" element={<CookiePolicy />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfUse />} />

          {/* Rotte per i Servizi */}
          <Route path="/servizi" element={<Servizi />} />
          <Route path="/servizi/it-network" element={<ITNetwork />} />
          <Route path="/servizi/cybersecurity" element={<Cybersecurity />} />
          <Route path="/servizi/telecom-iot" element={<TelecomIot />} />
          <Route path="/servizi/satcom" element={<Satcom />} />
          <Route path="/servizi/consulting" element={<Consulting />} />

          {/* Rotte per le Soluzioni */}
          <Route path="/soluzioni" element={<Soluzioni/>} />
          <Route path="/soluzioni/networking" element={<Networking />} />
          <Route path="/soluzioni/security" element={<Security />} />
          <Route path="/soluzioni/gnss" element={<GNSS />} />
          <Route path="/soluzioni/programmi" element={<Programmi />} />

          {/* Rotte per Articoli Blog */}
          <Route path="/blog/cybersecurity-guide" element={<CybersecurityGuide />} />
          <Route path="/blog/vpn-guide" element={<VPNGuide />} />
          <Route path="/blog/gdpr-guide" element={<GDPRGuide />} />
          <Route path="/blog/ai-act-guide" element={<AiActGuide />} />
          <Route path="/blog/load-balancing-guide" element={<LoadBalancingGuide />} />
          <Route path="/blog/vlan-guide" element={<VlanGuide />} />
          <Route path="/blog/catena-del-freddo-guide" element={<GNSSCoolGuide />} />
          <Route path="/blog/gnss-logistic-guide" element={<GNSSLogisticGuide />} />
          <Route path="/blog/ai-cybersecurity-guide" element={<AICybersecurityGuide />} />
          <Route path="/blog/automazione-it-guide" element={<AutomazioneGuide />} />
          <Route path="/blog/cybersecurity-awareness-guide" element={<CybersecurityAwarenessGuide />} />
          <Route path="/blog/gdpr-manager-guide" element={<GDPRmanagerGuide />} />
          <Route path="/blog/case-study-manifattura-guide" element={<UseCaseGuide />} />
          <Route path="/blog/downtime-ict-guide" element={<ICTGuide />} />

          {/* Pagina non trovata */}
          <Route path="*" element={<div>Pagina non trovata</div>} />
        </Routes>
      </div>
      <ScrollToTopButton />
      <Footer />
    </>
  );
};

export default App;