import React from 'react';
import ScrollToTopButton from '../../components/common/ScrollToTopButton'; // Import corretto
import DatacenterImage from '../../assets/images/Datacenter.webp'; // Immagine principale
import VirtualizationImage from '../../assets/images/Virtualization.webp'; // Immagine del box
import FirewallImage from '../../assets/images/Firewall.webp'; // Immagine del firewall o scudo
import MonitoringImage from '../../assets/images/Monitoring.webp'; // Immagine del monitoraggio

const HeroITNetwork = () => {
  return (
    <>
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${DatacenterImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay per migliorare la leggibilità */}
        <div className="absolute inset-0 bg-blue-700/50"></div>

        {/* Contenuto Hero */}
        <div className="relative z-10 text-white px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-16">
            Infrastrutture di rete scalabili e affidabili
          </h1>
          <p className="text-lg md:text-2xl italic text-gray-100 mb-20">
            Ottimizza le tue operazioni con soluzioni IT su misura per la tua azienda.
          </p>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Richiedi una valutazione gratuita
          </a>
        </div>
      </section>

      {/* Scroll To Top Button */}
      <ScrollToTopButton /> {/* Utilizzo del componente */}

      {/* Blocco 2: Progettazione e Configurazione */}
      <section className="py-24 bg-gray-900 text-secondary">
        <div className="max-w-7xl mx-auto px-6 md:px-12">
          <div className="text-center mb-16">
            <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-10">
              Progettazione e Configurazione di LAN, WAN e VLAN
            </h2>
            <p className="text-xl md:text-1xl text-gray-300 leading-relaxed">
              Costruiamo infrastrutture di rete su misura per le esigenze della tua azienda, garantendo velocità,
              affidabilità e scalabilità. Dalla progettazione iniziale alla configurazione avanzata, ci assicuriamo
              che ogni componente funzioni al massimo delle sue potenzialità.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
            {/* Card 1 */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-[#007bff]">
              <h3 className="text-2xl font-bold mb-4">Progettazione Personalizzata</h3>
              <p className="text-lg text-gray-300">
                Analisi delle esigenze e progettazione su misura per ogni azienda.
              </p>
            </div>
            {/* Card 2 */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-[#007bff]">
              <h3 className="text-2xl font-bold mb-4">Reti Cablate e Wireless</h3>
              <p className="text-lg text-gray-300">
                Configurazione di reti cablate e wireless ad alte prestazioni.
              </p>
            </div>
            {/* Card 3 */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-[#007bff]">
              <h3 className="text-2xl font-bold mb-4">Monitoraggio Continuo</h3>
              <p className="text-lg text-gray-300">
                Monitoraggio costante per garantire affidabilità e performance.
              </p>
            </div>
          </div>

          <div className="text-center mt-20">
            <a
              href="/contact"
              className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Richiedi una valutazione gratuita
            </a>
          </div>
        </div>
      </section>

      {/* Blocco 3: Virtualizzazione e Gestione dei Server */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Sottotitolo */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Ottimizza le Risorse con la Virtualizzazione
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed mb-12">
        Con la virtualizzazione, trasformiamo il tuo hardware in un ambiente flessibile e scalabile. Miglioriamo
        le prestazioni dei server e ottimizziamo l’uso delle risorse, riducendo i costi e aumentando l’efficienza
        operativa.
      </p>
    </div>

    {/* Contenuto Testuale con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Elenco puntato */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li>Creazione di macchine virtuali per ottimizzare l’utilizzo delle risorse.</li>
          <li>Riduzione dei costi di manutenzione hardware.</li>
          <li>Monitoraggio e gestione avanzata dei server.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${VirtualizationImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una valutazione gratuita
      </a>
    </div>
  </div>
</section>

{/* Blocco 4: Sicurezza di Rete */}
<section className="py-24 bg-gray-900 text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Testo */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-10">
        Proteggi la Tua Rete con Sistemi Avanzati di Sicurezza
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed mb-12">
        La sicurezza della tua rete è la nostra priorità. Implementiamo firewall di ultima generazione, sistemi di
        rilevamento delle intrusioni (IDS/IPS) e strategie di protezione per salvaguardare i tuoi dati da ogni tipo
        di minaccia.
      </p>
    </div>

    {/* Contenuto Testuale con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li>Firewall avanzati per proteggere le comunicazioni.</li>
          <li>Sistemi IDS/IPS per monitorare e prevenire intrusioni.</li>
          <li>Sicurezza dei dati e protezione da malware.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${FirewallImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una valutazione gratuita
      </a>
    </div>
  </div>
</section>

{/* Blocco 5: Monitoraggio e Manutenzione della Rete */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Testo */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-10">
        Manutenzione Proattiva per Prestazioni Ottimali
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed mb-12">
        Monitoriamo costantemente la tua rete per rilevare problemi prima che diventino critici. Offriamo interventi
        di manutenzione programmata e assistenza 24/7 per garantire la continuità operativa.
      </p>
    </div>

    {/* Contenuto Testuale con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li>Monitoraggio in tempo reale delle prestazioni di rete.</li>
          <li>Interventi di manutenzione programmata per evitare downtime.</li>
          <li>Supporto tecnico rapido e disponibile 24/7.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${MonitoringImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una valutazione gratuita
      </a>
    </div>
  </div>
</section>

    {/* Blocco 6: CTA Finale */}
    <section className="py-24 bg-[#070918] text-secondary text-center">
  <div className="max-w-5xl mx-auto px-6 md:px-12">
    {/* Titolo */}
    <h2 className="text-4xl md:text-5xl font-bold mb-8">
      Hai bisogno di soluzioni su misura per la tua rete aziendale?
    </h2>
    {/* Testo */}
    <p className="text-xl leading-relaxed mb-12">
      Affida la tua rete a GalileoSat. Siamo pronti a progettare, ottimizzare e proteggere la tua infrastruttura IT.
    </p>
    {/* Pulsanti */}
    <div className="flex flex-col md:flex-row justify-center items-center gap-6">
      <a
        href="/contact"
        className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
  
  {/* Linea Blu Elettrico */}
  <div className="w-full h-[1px] bg-[#007bff] mt-20"></div>
</section>
    </>
  );
};

export default HeroITNetwork;