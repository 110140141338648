import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="terms-of-use-container">
      <section className="bg-[#070918] text-gray-300 p-8">
        <h1 className="text-4xl font-bold text-center text-yellow-500 mb-8">Termini di Utilizzo di GalileoSat</h1>
        <p className="mb-4 text-sm italic text-center">Ultimo aggiornamento: 08/01/2025</p>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">1. Scopo del Sito</h2>
          <p>
            Il sito web GalileoSat è progettato per fornire informazioni sui nostri servizi, soluzioni e contenuti formativi relativi a IT,
            cybersecurity, normative, intelligenza artificiale e tecnologie satellitari. L’accesso al sito è gratuito e destinato ad uso informativo e commerciale.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">2. Proprietà Intellettuale</h2>
          <p>
            Tutti i contenuti presenti sul sito, inclusi ma non limitati a testi, immagini, grafica, loghi, video, e codice sorgente, sono proprietà
            intellettuale di GalileoSat o dei suoi licenziatari e sono protetti dalle leggi sul copyright ed altri diritti di proprietà intellettuale.
          </p>
          <h3 className="text-xl font-bold text-white mb-4">Divieti:</h3>
          <ul className="list-disc ml-6">
            <li>
              Non è consentito copiare, modificare, distribuire, pubblicare, riprodurre o sfruttare in alcun modo i contenuti del sito senza
              l’autorizzazione scritta di GalileoSat.
            </li>
            <li>L’uso del logo o del nome aziendale per scopi non autorizzati è vietato.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">3. Utilizzo Consentito</h2>
          <h3 className="text-xl font-bold text-white mb-4">Gli utenti possono:</h3>
          <ul className="list-disc ml-6">
            <li>Accedere al sito per scopi informativi e non commerciali.</li>
            <li>Stampare o salvare parti dei contenuti per uso personale.</li>
          </ul>
          <h3 className="text-xl font-bold text-white mb-4">Gli utenti non possono:</h3>
          <ul className="list-disc ml-6">
            <li>Accedere al sito tramite metodi automatizzati (es. scraping, bot) senza autorizzazione.</li>
            <li>Tentare di violare la sicurezza o interferire con il funzionamento del sito.</li>
            <li>Pubblicare contenuti illegali, offensivi o inappropriati tramite il sito (es. nei form di contatto).</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">4. Limitazione di Responsabilità</h2>
          <p>
            GalileoSat si impegna a garantire che le informazioni sul sito siano accurate e aggiornate. Tuttavia:
          </p>
          <ul className="list-disc ml-6">
            <li>Non garantiamo che i contenuti siano privi di errori o omissioni.</li>
            <li>
              Non siamo responsabili per eventuali danni derivanti dall’uso del sito, inclusi ma non limitati a perdite economiche,
              interruzioni del servizio, o accesso non autorizzato ai dati.
            </li>
          </ul>
          <p>
            L’utilizzo del sito è a proprio rischio e pericolo. Ci riserviamo il diritto di modificare, sospendere o interrompere il sito
            in qualsiasi momento senza preavviso.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">5. Collegamenti a Siti di Terze Parti</h2>
          <p>
            Il nostro sito può contenere collegamenti a siti di terze parti per scopi informativi o commerciali. GalileoSat non è responsabile
            per i contenuti, le politiche sulla privacy o le pratiche di tali siti esterni. L’accesso a questi siti è interamente a tua discrezione.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">6. Privacy e Cookie</h2>
          <p>
            L’utilizzo del sito è regolato anche dalla nostra Privacy Policy e Cookie Policy. Utilizzando il sito, accetti il trattamento
            dei tuoi dati personali come descritto in tali documenti.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">7. Modifiche ai Termini di Utilizzo</h2>
          <p>
            Ci riserviamo il diritto di aggiornare o modificare questi Termini di Utilizzo in qualsiasi momento. Le modifiche saranno pubblicate
            su questa pagina ed avranno effetto immediato. Ti invitiamo a controllare regolarmente i Termini per rimanere informato.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">8. Legge Applicabile e Giurisdizione</h2>
          <p>
            Questi Termini di Utilizzo sono regolati dalla legge italiana. Qualsiasi controversia derivante dall’uso del sito sarà soggetta
            alla giurisdizione esclusiva del tribunale di Padova.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">9. Contatti</h2>
          <p>
            Per qualsiasi domanda o richiesta relativa a questi Termini di Utilizzo, puoi contattarci ai seguenti recapiti:
          </p>
          <p>Email: <a href="mailto:info@galileosat.com" className="text-blue-500 underline">info@galileosat.com</a></p>
          <p>Indirizzo: Via Bassa, Arzergrande (Pd)</p>
        </section>
      </section>

      <section className="bg-white text-gray-800 p-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-8">Terms of Use of GalileoSat</h1>
        <p className="mb-4 text-sm italic text-center">Last updated: 08/01/2025</p>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Purpose of the Website</h2>
          <p>
            The GalileoSat website is designed to provide information about our services, solutions, and educational content
            related to IT, cybersecurity, regulations, artificial intelligence, and satellite technologies. Access to the
            website is free and intended for informational and commercial purposes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Intellectual Property</h2>
          <p>
            All content on the website, including but not limited to text, images, graphics, logos, videos, and source code,
            is the intellectual property of GalileoSat or its licensors and is protected by copyright laws and other
            intellectual property rights.
          </p>
          <h3 className="text-xl font-bold text-gray-900 mb-4">Prohibitions:</h3>
          <ul className="list-disc ml-6">
            <li>
              It is not permitted to copy, modify, distribute, publish, reproduce, or exploit the content of the site in any
              way without written authorization from GalileoSat.
            </li>
            <li>Using the company logo or name for unauthorized purposes is prohibited.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Permitted Use</h2>
          <h3 className="text-xl font-bold text-gray-900 mb-4">Users may:</h3>
          <ul className="list-disc ml-6">
            <li>Access the website for informational and non-commercial purposes.</li>
            <li>Print or save parts of the content for personal use.</li>
          </ul>
          <h3 className="text-xl font-bold text-gray-900 mb-4">Users may not:</h3>
          <ul className="list-disc ml-6">
            <li>Access the site through automated methods (e.g., scraping, bots) without authorization.</li>
            <li>Attempt to breach security or interfere with the operation of the website.</li>
            <li>
              Publish illegal, offensive, or inappropriate content through the site (e.g., in contact forms).
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Limitation of Liability</h2>
          <p>
            GalileoSat strives to ensure that the information on the site is accurate and up-to-date. However:
          </p>
          <ul className="list-disc ml-6">
            <li>We do not guarantee that the content is free of errors or omissions.</li>
            <li>
              We are not responsible for any damages resulting from the use of the site, including but not limited to
              economic losses, service interruptions, or unauthorized data access.
            </li>
          </ul>
          <p>
            Use of the site is at your own risk. We reserve the right to modify, suspend, or discontinue the website at any
            time without notice.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Links to Third-Party Websites</h2>
          <p>
            Our website may contain links to third-party sites for informational or commercial purposes. GalileoSat is not
            responsible for the content, privacy policies, or practices of these external sites. Access to these sites is
            entirely at your discretion.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Privacy and Cookies</h2>
          <p>
            Use of the site is also governed by our Privacy Policy and Cookie Policy. By using the site, you agree to the
            processing of your personal data as described in these documents.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Changes to the Terms of Use</h2>
          <p>
            We reserve the right to update or modify these Terms of Use at any time. Changes will be published on this page
            and will take effect immediately. We encourage you to regularly review the Terms to stay informed.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Governing Law and Jurisdiction</h2>
          <p>
            These Terms of Use are governed by Italian law. Any disputes arising from the use of the site will be subject to
            the exclusive jurisdiction of the court of Padua.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Contacts</h2>
          <p>
            For any questions or requests regarding these Terms of Use, you can contact us at the following:
          </p>
          <p>Email: <a href="mailto:info@galileosat.com" className="text-blue-500 underline">info@galileosat.com</a></p>
          <p>Address: Via Bassa, Arzergrande (Pd)</p>
        </section>
      </section>
    </div>
  );
};

export default TermsOfUse;