import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
console.log('Token Mapbox:', process.env.REACT_APP_MAPBOX_TOKEN);

const ContactMap = () => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    if (!mapboxgl.accessToken) {
      console.error('Access token di Mapbox mancante.');
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [12.066, 45.268],
      zoom: 12,
      pitch: 60,
      bearing: -20,
      attributionControl: false,
    });

    new mapboxgl.Marker({ color: 'red' })
      .setLngLat([12.066, 45.268])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 })
          .setHTML('<p style="color: #000; font-weight: bold;">Sede GalileoSat</p>')
      )
      .addTo(map);

    return () => map.remove();
  }, []);

  return <div ref={mapContainerRef} className="h-96 w-full rounded-lg shadow-lg" />;
};

export default ContactMap;