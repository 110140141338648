import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import ContactMap from '../components/ContactMap'; // Import corretto

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_8e7pc81',
        'template_hrijhxb',
        {
          from_name: formData.name,
          reply_to: formData.email,
          subject: formData.subject,
          message: formData.message,
        },
        'o5YyN1RKEXhenhAdh'
      )
      .then(
        () => {
          setSuccessMessage('Il tuo messaggio è stato inviato con successo!');
          setFormData({ name: '', email: '', subject: '', message: '' });
        },
        (error) => {
          console.error('Errore durante l’invio del messaggio:', error.text);
        }
      );
  };

  return (
    <div className="min-h-screen bg-[#070918] py-12">
      <h1 className="text-4xl font-bold text-center text-yellow-500 mb-6">
        Contattaci
      </h1>
      <p className="text-center text-gray-300 mb-12">
        Siamo qui per rispondere alle tue domande. Compila il modulo o consulta la nostra posizione sulla mappa.
      </p>

      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 px-6">
        {/* Colonna sinistra: Modulo */}
        <div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium">
                Nome / Azienda <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                className="w-full p-3 bg-transparent border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="w-full p-3 bg-transparent border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>

            <div>
              <label htmlFor="subject" className="block text-sm font-medium">
                Oggetto <span className="text-red-500">*</span>
              </label>
              <select
                name="subject"
                id="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
                className="w-full p-3 bg-[#1c1c1c] text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              >
                <option value="" disabled>
                  Seleziona un argomento
                </option>
                <option value="Networking / Infrastrutture IT">Networking / Infrastrutture IT</option>
                <option value="Cybersecurity">Cybersecurity</option>
                <option value="Telecomunicazioni / IoT">Telecomunicazioni / IoT</option>
                <option value="Satcom">Satcom</option>
                <option value="Coaching / Formazione">Coaching / Formazione</option>
              </select>
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium">
                Messaggio <span className="text-red-500">*</span>
              </label>
              <textarea
                name="message"
                id="message"
                rows="5"
                value={formData.message}
                onChange={handleInputChange}
                required
                className="w-full p-3 bg-transparent border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-yellow-500 text-[#070918] py-3 rounded-lg font-bold hover:bg-yellow-400 transition"
            >
              Invia Messaggio
            </button>
          </form>

          {successMessage && <p className="mt-6 text-green-500 text-center">{successMessage}</p>}
        </div>

        {/* Colonna destra: Mappa */}
        <div className="flex justify-center items-start lg:items-center mt-8 lg:mt-0">
  <ContactMap />
</div>
      </div>
    </div>
  );
};

export default Contact;