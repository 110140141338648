import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-[#070918] text-gray-200 py-12 px-6">
      {/* Titolo */}
      <h1 className="text-4xl font-bold text-center text-yellow-500 mb-8">
        Politica sui Cookie
      </h1>

      {/* Testo in Italiano */}
      <div className="max-w-4xl mx-auto mb-12">
        <p>
          Questo sito utilizza cookie per migliorare l’esperienza dell’utente, analizzare il traffico e personalizzare i contenuti. I cookie sono piccoli file di testo che vengono memorizzati sul tuo dispositivo durante la navigazione. Alcuni cookie sono essenziali per il funzionamento del sito, mentre altri ci aiutano a migliorare le prestazioni ed offrire servizi personalizzati.
        </p>

        <h2 className="text-2xl font-bold mt-6">Tipologie di Cookie Utilizzate</h2>
        <ul className="list-disc pl-6">
          <li className="mt-2">
            <strong>Cookie Essenziali:</strong> Questi cookie sono necessari per il corretto funzionamento del sito e non possono essere disabilitati nei nostri sistemi.
          </li>
          <li className="mt-2">
            <strong>Cookie di Performance:</strong> Utilizzati per analizzare il traffico del sito e ottimizzare le prestazioni.
          </li>
          <li className="mt-2">
            <strong>Cookie Funzionali:</strong> Consentono al sito di ricordare le tue scelte per fornire funzionalità migliorate.
          </li>
          <li className="mt-2">
            <strong>Cookie di Marketing:</strong> Utilizzati per tracciare l’attività degli utenti e mostrare annunci pertinenti in base agli interessi.
          </li>
        </ul>

        <p className="mt-6">
          Puoi gestire le preferenze relative ai cookie tramite il nostro banner di consenso o accedendo alla sezione "Gestione Cookie" in fondo a ogni pagina.
        </p>

        <h2 className="text-2xl font-bold mt-6">Dettaglio dei Cookie Utilizzati</h2>
        <table className="w-full text-left mt-4 border-collapse border border-gray-500">
          <thead>
            <tr>
              <th className="border border-gray-500 px-4 py-2">Nome del Cookie</th>
              <th className="border border-gray-500 px-4 py-2">Tipologia</th>
              <th className="border border-gray-500 px-4 py-2">Scopo</th>
              <th className="border border-gray-500 px-4 py-2">Durata</th>
              <th className="border border-gray-500 px-4 py-2">Fornitore</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-500 px-4 py-2">_ga</td>
              <td className="border border-gray-500 px-4 py-2">Performance</td>
              <td className="border border-gray-500 px-4 py-2">Analisi del traffico tramite Google Analytics</td>
              <td className="border border-gray-500 px-4 py-2">2 anni</td>
              <td className="border border-gray-500 px-4 py-2">Google</td>
            </tr>
            <tr>
              <td className="border border-gray-500 px-4 py-2">cookie_consent</td>
              <td className="border border-gray-500 px-4 py-2">Essenziale</td>
              <td className="border border-gray-500 px-4 py-2">Memorizza le preferenze dell'utente sui cookie</td>
              <td className="border border-gray-500 px-4 py-2">1 anno</td>
              <td className="border border-gray-500 px-4 py-2">GalileoSat</td>
            </tr>
            <tr>
              <td className="border border-gray-500 px-4 py-2">ads_tracking</td>
              <td className="border border-gray-500 px-4 py-2">Marketing</td>
              <td className="border border-gray-500 px-4 py-2">Tracciamento per annunci personalizzati</td>
              <td className="border border-gray-500 px-4 py-2">30 giorni</td>
              <td className="border border-gray-500 px-4 py-2">Google Ads</td>
            </tr>
          </tbody>
        </table>

        <p className="mt-6">
          Per ulteriori informazioni su come trattiamo i dati personali raccolti tramite cookie, consulta la nostra <a href="/privacy" className="text-blue-500 hover:underline">Privacy Policy</a>.
        </p>

        <p className="mt-6">
          Questa sezione potrebbe essere soggetta a modifiche per garantire la conformità con le normative vigenti. Ti invitiamo a consultarla regolarmente.
        </p>
      </div>

      {/* Testo in Inglese */}
      <div className="max-w-4xl mx-auto bg-gray-100 text-gray-800 p-6 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Cookie Policy</h2>
        <p>
          This site uses cookies to enhance user experience, analyze traffic, and personalize content. Cookies are small text files stored on your device during navigation. Some cookies are essential for site functionality, while others help us improve performance and offer personalized services.
        </p>

        <h3 className="text-xl font-bold mt-6">Types of Cookies Used</h3>
        <ul className="list-disc pl-6">
          <li className="mt-2">
            <strong>Essential Cookies:</strong> These cookies are necessary for the site to function properly and cannot be disabled in our systems.
          </li>
          <li className="mt-2">
            <strong>Performance Cookies:</strong> Used to analyze site traffic and optimize performance.
          </li>
          <li className="mt-2">
            <strong>Functional Cookies:</strong> Enable the site to remember your choices (e.g., language or region) to provide enhanced and personalized functionality.
          </li>
          <li className="mt-2">
            <strong>Marketing Cookies:</strong> Used to track user activity and show relevant ads based on interests.
          </li>
        </ul>

        <p className="mt-6">
          You can manage your cookie preferences through our consent banner displayed on your first visit or by accessing the "Cookie Management" section available at the bottom of every page.
        </p>

        <h3 className="text-xl font-bold mt-6">Details of Cookies Used</h3>
        <table className="w-full text-left mt-4 border-collapse border border-gray-500">
          <thead>
            <tr>
              <th className="border border-gray-500 px-4 py-2">Cookie Name</th>
              <th className="border border-gray-500 px-4 py-2">Type</th>
              <th className="border border-gray-500 px-4 py-2">Purpose</th>
              <th className="border border-gray-500 px-4 py-2">Duration</th>
              <th className="border border-gray-500 px-4 py-2">Provider</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-500 px-4 py-2">_ga</td>
              <td className="border border-gray-500 px-4 py-2">Performance</td>
              <td className="border border-gray-500 px-4 py-2">Traffic analysis via Google Analytics</td>
              <td className="border border-gray-500 px-4 py-2">2 years</td>
              <td className="border border-gray-500 px-4 py-2">Google</td>
            </tr>
            <tr>
              <td className="border border-gray-500 px-4 py-2">cookie_consent</td>
              <td className="border border-gray-500 px-4 py-2">Essential</td>
              <td className="border border-gray-500 px-4 py-2">Stores user cookie preferences</td>
              <td className="border border-gray-500 px-4 py-2">1 year</td>
              <td className="border border-gray-500 px-4 py-2">GalileoSat</td>
            </tr>
            <tr>
              <td className="border border-gray-500 px-4 py-2">ads_tracking</td>
              <td className="border border-gray-500 px-4 py-2">Marketing</td>
              <td className="border border-gray-500 px-4 py-2">Tracking for personalized ads</td>
              <td className="border border-gray-500 px-4 py-2">30 days</td>
              <td className="border border-gray-500 px-4 py-2">Google Ads</td>
            </tr>
          </tbody>
        </table>

        <p className="mt-6">
          For more information on how we process personal data collected through cookies, please see our <a href="/privacy" className="text-blue-500 hover:underline">Privacy Policy</a>.
        </p>

        <p className="mt-6">
          This section may be subject to changes to ensure compliance with current regulations. We encourage you to review it regularly.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;