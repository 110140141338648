import React from 'react';
import MeetingImage from '../../assets/images/Meeting.webp'; // Immagine principale Hero
import ChatAIImage from '../../assets/images/ChatAI.webp'; // Immagine principale del blocco
import AIRulesImage from '../../assets/images/AIRules.webp'; // Immagine principale del blocco
import Meeting2Image from '../../assets/images/Meeting2.webp'; // Immagine principale del blocco

const ITCoaching = () => {
  return (
    <>
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${MeetingImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-700/60"></div>

        {/* Contenuto Hero */}
        <div className="relative z-10 text-white px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-8">
            IT Coaching: Competenze e Sicurezza per l’Innovazione Digitale
          </h1>
          <p className="text-lg md:text-2xl italic text-gray-100 mb-12">
            Dai corsi tecnici alla consulenza strategica, il nostro IT Coaching forma professionisti pronti a gestire le nuove sfide tecnologiche, incluse le normative e la sicurezza legate all’intelligenza artificiale e alle infrastrutture IT.
          </p>
          <div className="flex flex-col md:flex-row justify-center gap-4">
            <a
              href="/soluzioni/programmi"
              className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Scopri i Nostri Programmi di Coaching
            </a>
          </div>
        </div>
      </section>

      {/* Blocco 2: Formazione Personalizzata */}
      <section className="py-24 bg-gray-900 text-secondary">
        <div className="max-w-7xl mx-auto px-6 md:px-12">
          {/* Titolo e Descrizione */}
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-yellow-500 mb-8">
              Corsi su Misura per le Esigenze della Tua Azienda
            </h2>
            <p className="text-xl text-gray-300 leading-relaxed mb-12">
              Progettiamo percorsi formativi personalizzati per colmare lacune tecniche e affrontare le sfide della trasformazione digitale. Offriamo competenze pratiche su reti, cloud, sicurezza e tecnologie emergenti come l’AI.
            </p>
          </div>

          {/* Griglia 2x2 Card 3D */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Card 1 */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-gray-300 transform hover:-translate-y-2 transition duration-300">
              <h3 className="text-2xl font-bold text-yellow-500 mb-4">Sicurezza Informatica</h3>
              <p className="text-lg text-gray-300">
                Protezione contro attacchi informatici e formazione sulle best practice per mantenere i dati al sicuro.
              </p>
            </div>

            {/* Card 2 */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-gray-300 transform hover:-translate-y-2 transition duration-300">
              <h3 className="text-2xl font-bold text-green-500 mb-4">Tecnologie IoT</h3>
              <p className="text-lg text-gray-300">
                Soluzioni per monitorare, gestire e connettere dispositivi IoT in scenari aziendali reali.
              </p>
            </div>

            {/* Card 3 */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-gray-300 transform hover:-translate-y-2 transition duration-300">
              <h3 className="text-2xl font-bold text-red-500 mb-4">Tecnologie Emergenti AI</h3>
              <p className="text-lg text-gray-300">
                Formazione su AI applicata per ottimizzare processi e innovare le infrastrutture IT.
              </p>
            </div>

            {/* Card 4 */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-gray-300 transform hover:-translate-y-2 transition duration-300">
              <h3 className="text-2xl font-bold text-yellow-500 mb-4">Trasformazione Digitale</h3>
              <p className="text-lg text-gray-300">
                Strategia e formazione per guidare la tua azienda nel futuro digitale.
              </p>
            </div>
          </div>

          {/* CTA Locale */}
          <div className="text-center mt-16">
            <a
              href="/contact"
              className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Contattaci Ora
            </a>
          </div>
        </div>
      </section>

        {/* Blocco 3: Cybersecurity Awareness e AI Security */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Preparazione alla Sicurezza IT e alle Sfide dell’Intelligenza Artificiale
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Le nuove tecnologie come l’AI offrono opportunità straordinarie, ma richiedono anche un focus sulla sicurezza. Prepariamo il tuo team a identificare e mitigare i rischi legati all’AI, oltre a promuovere consapevolezza e best practice generali in materia di cybersecurity.
      </p>
    </div>

    {/* Contenuto con Punti Chiave */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li>
            <strong>Sicurezza delle Tecnologie AI:</strong> Analisi dei rischi e strategie di mitigazione per applicazioni basate su intelligenza artificiale.
          </li>
          <li>
            <strong>Riconoscere le Minacce IT:</strong> Phishing, malware e attacchi social engineering.
          </li>
          <li>
            <strong>Compliance Normativa:</strong> Conformità al GDPR e ad altre normative sulla protezione dei dati e sull’utilizzo etico dell’AI.
          </li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${ChatAIImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Contattaci Ora
      </a>
    </div>
  </div>
</section>

{/* Blocco 4: Leadership Tecnologica e Normative */}
<section className="py-24 bg-gray-900 text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Formare Leader per la Sicurezza e la Conformità Tecnologica
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Il nostro coaching non si limita alle competenze tecniche. Aiutiamo i leader IT a navigare normative complesse, adottare strategie etiche e implementare tecnologie emergenti come l’AI in modo sicuro e conforme.
      </p>
    </div>

    {/* Contenuto con Punti Chiave */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li>
            <strong>Gestione dei Progetti IT:</strong> Focus su progetti legati a tecnologie emergenti.
          </li>
          <li>
            <strong>Normative e Compliance AI:</strong> Introduzione ai requisiti legali e di governance per l’adozione dell’intelligenza artificiale.
          </li>
          <li>
            <strong>Soft Skills e Strategia:</strong> Sviluppo di capacità di leadership per ispirare e gestire il cambiamento tecnologico.
          </li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${AIRulesImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Contattaci Ora
      </a>
    </div>
  </div>
</section>

{/* Blocco 5: Supporto Continuo e Aggiornamenti */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Supporto Continuo per la Sicurezza e la Conformità
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Le tecnologie evolvono rapidamente, così come le normative. Offriamo supporto continuo per mantenere il tuo team sempre aggiornato sulle ultime tendenze tecnologiche e leggi, garantendo conformità e sicurezza.
      </p>
    </div>

    {/* Contenuto con Punti Chiave */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li>
            <strong>Sessioni di Aggiornamento:</strong> Formazione regolare su normative e tecnologie emergenti.
          </li>
          <li>
            <strong>Consulenza On-Demand:</strong> Risoluzione di problemi legati alla sicurezza e alla governance.
          </li>
          <li>
            <strong>Materiali Didattici:</strong> Risorse aggiornate su AI, sicurezza IT e compliance.
          </li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${Meeting2Image})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Contattaci Ora
      </a>
    </div>
  </div>
</section>

    {/* Blocco 6: CTA Finale */}
    <section className="py-24 bg-[#070918] text-secondary text-center">
      <div className="max-w-5xl mx-auto px-6 md:px-12">
        {/* Titolo */}
        <h3 className="text-4xl md:text-5xl font-bold mb-8">
          Vuoi Garantire la Sicurezza e la Competenza del Tuo Team?
        </h3>
        {/* Testo */}
        <p className="text-xl leading-relaxed mb-12">
          Contattaci per scoprire come il nostro IT Coaching può migliorare le competenze del tuo team e aiutarti a gestire tecnologie avanzate e normative complesse.
        </p>
        {/* Pulsanti CTA */}
        <div className="flex flex-col md:flex-row justify-center items-center gap-6">
          <a
            href="/contact"
            className="bg-transparent border-2 border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
          >
            Richiedi un Programma Personalizzato
          </a>
        </div>
      </div>
      {/* Linea Separatrice */}
      <div className="w-full h-[1px] bg-gray-500 mt-20"></div>
    </section>
    </>
  );
};

export default ITCoaching;

