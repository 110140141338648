import React from 'react';
import { Link } from 'react-router-dom';

const Servizi = () => {
  const services = [
    {
      title: "Networking / IT",
      description: "Ottimizzazione delle reti aziendali e infrastrutture IT.",
      link: "/servizi/it-network",
    },
    {
        title: "Cybersecurity",
        description: "Protezione completa contro minacce informatiche.",
        link: "/servizi/cybersecurity",
    },
    {
      title: "Telecomunicazioni / IoT",
      description: "Soluzioni avanzate per dispositivi connessi.",
      link: "/servizi/telecom-iot",
    },
    {
      title: "Satcom",
      description: "Comunicazioni satellitari per aziende moderne.",
      link: "/servizi/satcom",
    },
    {
      title: "Consulting",
      description: "Consulenza personalizzata per ogni esigenza IT.",
      link: "/servizi/consulting",
    },
  ];

  return (
    <div className="min-h-screen bg-[#070918] py-12">
      <h1 className="text-4xl font-bold text-center text-yellow-500 mb-10">
        I nostri Servizi
      </h1>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-6">
        {services.map((service, index) => (
          <div key={index} className="bg-[#1c1c1c] p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold text-white mb-4">{service.title}</h2>
            <p className="text-gray-300 mb-6">{service.description}</p>
            <Link
              to={service.link}
              className="text-yellow-500 font-bold hover:text-yellow-400 transition"
            >
              Scopri di più →
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Servizi;