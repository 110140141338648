import React from 'react';
import CyberlockImage from '../assets/images/Cyberlock.webp';
import ServerImage from '../assets/images/Server.webp';
import TrackingImage from '../assets/images/Tracking.webp';
import HeroImage from '../assets/images/Hero2.webp';
import { Swiper, SwiperSlide } from 'swiper/react'; // Importa Swiper e SwiperSlide
import { Navigation } from 'swiper/modules'; // Importa il modulo Navigation
import 'swiper/css'; // Stili generali di Swiper
import 'swiper/css/navigation'; // Stili specifici per la navigazione

const Home = () => {
  return (
    <div>
      {/* Hero Section */}
<section
id="hero-home" // ID univoco per la Hero della Home
  className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
  style={{
    backgroundImage: `url(${HeroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
>
  {/* Overlay blu */}
  <div className="absolute inset-0 bg-blue-700/50"></div>
  
  {/* Testo sopra l'overlay */}
  <div className="relative z-10 text-white px-4">
    <h1 className="text-4xl md:text-6xl font-bold mb-20"> {/* Incrementato il margin-bottom */}
      Innovazione che guarda oltre
    </h1>
    <p className="text-lg md:text-2xl italic text-gray-100 mb-24"> {/* Incrementato il margin-bottom */}
      S o l u z i o n i  -  T e c n o l o g i c h e  -  A v a n z a t e
    </p>
    <div className="mt-16"> {/* Incrementato il margin-top */}
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded hover:bg-gray-400"
      >
        Contattaci Ora
      </a>
    </div>
  </div>
</section>

      {/* Punti di Forza */}
      <section className="bg-gray-900 py-20">
  <div className="max-w-7xl mx-auto px-4 text-center">
    {/* Titolo */}
    <h2 className="text-3xl md:text-4xl font-bold mb-6 text-yellow">
      Perché scegliere GalileoSat?
    </h2>
    <p className="text-lg text-gray-200 mb-12">
      Le nostre soluzioni sono progettate per offrire sicurezza, innovazione e prestazioni elevate in ogni contesto.
    </p>

    {/* Griglia delle card */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
      {/* Card 1 */}
      <div className="bg-gray-100 p-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300">
        <h3 className="text-xl font-bold mb-4 text-blue-700">Network IT</h3>
        <p className="text-gray-700">
          Offriamo soluzioni avanzate per la gestione di reti e infrastrutture IT, garantendo efficienza e scalabilità.
        </p>
      </div>

      {/* Card 2 */}
      <div className="bg-gray-100 p-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300">
        <h3 className="text-xl font-bold mb-4 text-red-700">Cybersecurity</h3>
        <p className="text-gray-700">
          Implementiamo tecnologie avanzate per proteggere i tuoi dati e prevenire le minacce informatiche.
        </p>
      </div>

      {/* Card 3 */}
      <div className="bg-gray-100 p-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300">
        <h3 className="text-xl font-bold mb-4 text-green-700">Satellite Solutions</h3>
        <p className="text-gray-700">
          Sfruttiamo dati GNSS e IoT per monitoraggio, analisi e controllo precisi in settori industriali e logistici.
        </p>
      </div>
    </div>
    <p className="text-lg text-gray-200 mb-12">
    La sicurezza non è solo una questione tecnica, ma un investimento strategico per il futuro della tua azienda. Con le nostre soluzioni, puoi affrontare le sfide digitali con fiducia, sapendo di avere una protezione completa e personalizzata.
    </p>
    

    {/* CTA */}
    <div>
      <a
        href="/soluzioni"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition duration-300 font-bold text-lg"
      >
        Scopri le Soluzioni
      </a>
    </div>
  </div>
</section>
  
      {/* Servizi Principali */}
<section className="bg-[#070918] text-gray-900 py-20">
  <div className="max-w-7xl mx-auto px-4 text-center">
    <h2 className="text-3xl md:text-4xl font-bold mb-6 text-yellow-500">
      Soluzioni su misura per la tua azienda
    </h2>
    <p className="text-lg text-gray-400 mb-12">
      Esplora i nostri servizi progettati per soddisfare le esigenze più complesse e garantire prestazioni eccellenti.
    </p>

    {/* Griglia Servizi */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {/* Servizio 1 */}
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col items-center text-center border border-blue-500">
        <img
          src={ServerImage}
          alt="Infrastruttura IT e Reti"
          className="w-full h-48 object-cover mb-4 rounded-md"
        />
        <h3 className="text-xl font-bold mb-2 text-blue-500">Infrastruttura IT e Reti</h3>
        <p className="text-gray-300 mb-4">
          Ottimizza le tue operazioni con reti affidabili e scalabili.
        </p>
        <a
          href="/servizi/it-network"
          className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded hover:bg-secondary hover:text-primary transition font-bold"
        >
          Scopri di più
        </a>
      </div>

      {/* Servizio 2 */}
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col items-center text-center border border-red-500">
        <img
          src={CyberlockImage}
          alt="Cybersecurity"
          className="w-full h-48 object-cover mb-4 rounded-md"
        />
        <h3 className="text-xl font-bold mb-2 text-red-500">Cybersecurity</h3>
        <p className="text-gray-300 mb-4">
          Proteggi la tua azienda con sistemi di sicurezza avanzati.
        </p>
        <a
          href="/servizi/cybersecurity"
          className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded hover:bg-secondary hover:text-primary transition font-bold"
        >
          Scopri di più
        </a>
      </div>

      {/* Servizio 3 */}
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col items-center text-center border border-green-500">
        <img
          src={TrackingImage}
          alt="Tecnologie Satellitari"
          className="w-full h-48 object-cover mb-4 rounded-md"
        />
        <h3 className="text-xl font-bold mb-2 text-green-500">Tecnologie Satellitari</h3>
        <p className="text-gray-300 mb-4">
          Soluzioni GNSS per monitoraggio e analisi avanzati.
        </p>
        <a
          href="/servizi/satcom"
          className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded hover:bg-secondary hover:text-primary transition font-bold"
        >
          Scopri di più
        </a>
      </div>
    </div>

    {/* CTA Globale */}
    <div className="mt-16">
      <a
        href="/servizi"
        className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
      >
        Esplora Tutti i Servizi
      </a>
    </div>
  </div>
</section>

<section className="bg-gray-900 text-secondary py-20">
  <div className="max-w-7xl mx-auto px-4 text-center">
    {/* Titolo */}
    <h2 className="text-3xl md:text-4xl font-bold mb-6">Progetti di Successo</h2>
    <p className="text-lg text-gray-400 mb-12">
      Scopri come le nostre soluzioni hanno aiutato aziende a raggiungere i loro obiettivi.
    </p>

    {/* Griglia di progetti */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {/* Progetto 1 */}
      <div className="bg-[#1c1c1c] p-6 rounded-lg shadow-lg hover:scale-105 border border-blue-500 transition-transform duration-300">
        <h3 className="text-xl font-bold mb-4">Progetto Networking</h3>
        <p className="text-gray-300">
          Migrazione di un Data Center di medie dimensioni integrando soluzioni cloud native e sistemi on-premise.
        </p>
      </div>

      {/* Progetto 2 */}
      <div className="bg-[#1c1c1c] p-6 rounded-lg shadow-lg hover:scale-105 border border-red-500 transition-transform duration-300">
        <h3 className="text-xl font-bold mb-4">Progetto Security</h3>
        <p className="text-gray-300">
          Ottimizzate infrastrutture telematiche in aeroporti e control room, assicurando continuità operativa in contesti critici.
        </p>
      </div>

      {/* Progetto 3 */}
      <div className="bg-[#1c1c1c] p-6 rounded-lg shadow-lg hover:scale-105 border border-green-500 transition-transform duration-300">
        <h3 className="text-xl font-bold mb-4">Progetto Satellite</h3>
        <p className="text-gray-300">
          Dati GNSS per ottimizzare la pianificazione dei consumi, la manutenzione ordinaria/predittiva e monitoraggio della catena del freddo.
        </p>
      </div>
    </div>

    {/* CTA Globale */}
    <div className="mt-12">
      <a
        href="/soluzioni"
        className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
      >
        Esplora Le Nostre Soluzioni
      </a>
    </div>
  </div>
</section>

{/* Testimonianze - Carosello */}
<section className="bg-[#070918] text-secondary py-20">
  <div className="max-w-7xl mx-auto px-4">
    {/* Titolo */}
    <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center text-yellow-500">
      Cosa dicono i nostri clienti
    </h2>
    <p className="text-lg text-gray-300 mb-12 text-center">
      Leggi le testimonianze di chi ha scelto le nostre soluzioni.
    </p>

    {/* Carousel */}
<Swiper
  modules={[Navigation]}
  navigation
  spaceBetween={20}
  slidesPerView={1}
  breakpoints={{
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
  }}
>
  {/* Testimonianza 1 */}
  <SwiperSlide>
  <div className="bg-gray-800 p-8 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 relative flex flex-col justify-between h-[230px]">
      {/* Stelline */}
      <div className="absolute top-4 right-4 text-yellow-400 text-lg">
        ★★★★☆
      </div>
      {/* Nome e ruolo */}
      <div className="absolute top-4 left-4 text-white">
      <h3 className="text-lg font-bold text-green-500">Mario Marini</h3>
        <p className="italic text-gray-400">CEO, SecureNet</p>
      </div>
      {/* Testimonianza */}
      <p className="text-gray-300 italic text-center mt-16">
        “Grazie a GalileoSat, la nostra rete aziendale è ora protetta da attacchi informatici complessi. La loro competenza in Cybersecurity è ineguagliabile.”
      </p>
    </div>
  </SwiperSlide>

  {/* Testimonianza 2 */}
  <SwiperSlide>
  <div className="bg-gray-800 p-8 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 relative flex flex-col justify-between h-[230px]">
      <div className="absolute top-4 right-4 text-yellow-400 text-lg">
        ★★★★★
      </div>
      <div className="absolute top-4 left-4 text-white">
      <h3 className="text-lg font-bold text-green-500">Laura Cristini</h3>
        <p className="italic text-gray-400">Project Manager, SkyTrack Solutions</p>
      </div>
      <p className="text-gray-300 italic text-center mt-16">
        “Abbiamo migliorato il monitoraggio delle risorse aziendali con tecnologie GNSS all'avanguardia, riducendo costi e inefficienze.”
      </p>
    </div>
  </SwiperSlide>

  {/* Testimonianza 3 */}
  <SwiperSlide>
  <div className="bg-gray-800 p-8 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 relative flex flex-col justify-between h-[230px]">
      <div className="absolute top-4 right-4 text-yellow-400 text-lg">
        ★★★★★
      </div>
      <div className="absolute top-4 left-4 text-white">
      <h3 className="text-lg font-bold text-green-500">Giovanni Baldini</h3>
        <p className="italic text-gray-400">CTO, Smart Logistics</p>
      </div>
      <p className="text-gray-300 italic text-center mt-16">
        “La loro esperienza ci ha permesso di ottimizzare le infrastrutture IT e garantire continuità operativa anche nei momenti critici.”
      </p>
    </div>
  </SwiperSlide>

  {/* Testimonianza 4 */}
  <SwiperSlide>
  <div className="bg-gray-800 p-8 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 relative flex flex-col justify-between h-[230px]">
      <div className="absolute top-4 right-4 text-yellow-400 text-lg">
        ★★★★☆
      </div>
      <div className="absolute top-4 left-4 text-white">
      <h3 className="text-lg font-bold text-green-500">Francesca Neri</h3>
        <p className="italic text-gray-400">Marketing Manager, DigitalPro</p>
      </div>
      <p className="text-gray-300 italic text-center mt-16">
        “Grazie alla consulenza di GalileoSat, abbiamo trasformato la nostra strategia IT e migliorato l’efficienza aziendale.”
      </p>
    </div>
  </SwiperSlide>

  {/* Testimonianza 5 */}
  <SwiperSlide>
  <div className="bg-gray-800 p-8 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 relative flex flex-col justify-between h-[230px]">
      <div className="absolute top-4 right-4 text-yellow-400 text-lg">
        ★★★★★
      </div>
      <div className="absolute top-4 left-4 text-white">
      <h3 className="text-lg font-bold text-green-500">Alessandro Russo</h3>
        <p className="italic text-gray-400">CEO, GreenTech</p>
      </div>
      <p className="text-gray-300 italic text-center mt-16">
        “Le loro soluzioni satellitari ci hanno aiutato a migliorare il monitoraggio ambientale, garantendo maggiore precisione e sostenibilità.”
      </p>
    </div>
  </SwiperSlide>

  {/* Testimonianza 6 */}
  <SwiperSlide>
  <div className="bg-gray-800 p-8 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 relative flex flex-col justify-between h-[230px]">
      <div className="absolute top-4 right-4 text-yellow-400 text-lg">
        ★★★★☆
      </div>
      <div className="absolute top-4 left-4 text-white">
      <h3 className="text-lg font-bold text-green-500">Chiara Moretti</h3>
        <p className="italic text-gray-400">CTO, Innovatech</p>
      </div>
      <p className="text-gray-300 italic text-center mt-16">
        “Con GalileoSat abbiamo ridotto i tempi di manutenzione del 20%, ottimizzando la gestione delle nostre reti.”
      </p>
    </div>
  </SwiperSlide>
</Swiper>
        </div>
      </section>
    </div>
  );
};

export default Home;

