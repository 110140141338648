import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

const FooterMap = () => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    // Configura la chiave API di Mapbox
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    console.log('Token Mapbox:', process.env.REACT_APP_MAPBOX_TOKEN);

    // Crea una nuova mappa
    const map = new mapboxgl.Map({
      container: mapContainerRef.current, // Riferimento al container
      style: 'mapbox://styles/mapbox/dark-v10', // Stile della mappa
      center: [12.066221, 45.268747], // Coordinate [longitudine, latitudine]
      zoom: 13, // Livello di zoom
      pitch: 45,
    bearing: -17.6,
      attributionControl: false, // Nasconde la didascalia di Mapbox
    });

    new mapboxgl.Marker({ color: 'red' })
  .setLngLat([12.066, 45.268]) // Coordinate del marker
  .setPopup(
    new mapboxgl.Popup({ offset: 25 }) // Offset per posizionare il popup meglio
      .setHTML('<p style="color: #000; font-weight: bold;">Sede GalileoSat</p>') // Colore scuro
  )
  .addTo(map);


    // Rimuove le risorse della mappa al demount del componente
    return () => map.remove();
  }, []);

  return (
    <div
  ref={mapContainerRef}
  className="h-48 w-full rounded-lg shadow-lg border border-gray-700 mt-6 overflow-hidden"
></div>
  );
};

export default FooterMap;