import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accetta"
      declineButtonText="Rifiuta"
      enableDeclineButton
      style={{
        background: "#070918",
        color: "#d4d4d4",
        fontSize: "16px",
      }}
      buttonStyle={{
        background: "#FFD700",
        color: "#070918",
        fontWeight: "bold",
        borderRadius: "5px",
        padding: "10px 20px",
      }}
      declineButtonStyle={{
        background: "#d4d4d4",
        color: "#070918",
        fontWeight: "bold",
        borderRadius: "5px",
        padding: "10px 20px",
      }}
      cookieName="galileosat-cookie-consent"
      onAccept={() => console.log("Cookie accettati")}
      onDecline={() => console.log("Cookie rifiutati")}
    >
      Utilizziamo i cookie per migliorare la tua esperienza. Per saperne di più, leggi la nostra{" "}
      <a href="/cookie" className="underline text-yellow-500 hover:text-yellow-300">
        Cookie Policy
      </a>.
    </CookieConsent>
  );
};

export default CookieBanner;
