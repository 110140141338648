import React from 'react';
import CoachingImage from '../../assets/images/Coaching.webp'; // Percorso corretto dell'immagine
import { FaCheck } from 'react-icons/fa';


const Programmi = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#070918] to-[#111827] text-gray-300">
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${CoachingImage})`, // Immagine di sfondo
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-900/80"></div>
        {/* Contenuto Hero */}
        <div className="relative z-10 text-white px-6">
          <h1 className="text-4xl md:text-6xl font-extrabold mb-20">
          Sicurezza, Normative e Innovazione per il Tuo Business Digitale
          </h1>
          <p className="text-lg md:text-2xl italic mb-12">
            Proteggiamo i tuoi dati e formiamo il tuo team sulle normative IT - Cybersecurity - AI - IoT - Telco.
          </p>
          <p className="text-lg md:text-2xl mb-8">
          Soluzioni pratiche per aziende moderne.
          </p>
          <div className="mt-28"></div>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Richiedi una Consulenza Gratuita
          </a>
        </div>
      </section>

      {/* Problema Principale e Soluzione Offerta */}
<section className="py-20 bg-gray-100 text-gray-800">
  <div className="max-w-7xl mx-auto px-6 md:px-12 grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
    {/* Testo descrittivo */}
    <div className="order-2 md:order-1">
      <h2 className="text-3xl md:text-4xl font-bold mb-8 text-blue-900">
        Le Sfide delle Normative Digitali, Risolte
      </h2>
      <p className="text-lg md:text-xl text-gray-700 mb-8">
        In un mondo regolamentato e in continua evoluzione, le aziende devono affrontare normative complesse che governano la sicurezza dei dati, la privacy, e l'uso delle tecnologie digitali. La nostra missione è aiutarti a:
      </p>
      <div className="space-y-6">
        <div className="flex items-start">
          <div className="text-green-500 text-2xl mr-4">✔</div>
          <p className="text-gray-700">
            <span className="font-bold text-blue-900">Navigare le normative senza stress.</span>
          </p>
        </div>
        <div className="flex items-start">
          <div className="text-green-500 text-2xl mr-4">✔</div>
          <p className="text-gray-700">
            <span className="font-bold text-blue-900">Ridurre i rischi aziendali.</span>
          </p>
        </div>
        <div className="flex items-start">
          <div className="text-green-500 text-2xl mr-4">✔</div>
          <p className="text-gray-700">
            <span className="font-bold text-blue-900">Proteggere la tua rete, i tuoi asset e i tuoi dipendenti.</span>
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-12">
        <a
          href="/contact"
          className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
        >
          Richiedi una Consulenza Gratuita
        </a>
      </div>
    </div>

    {/* Immagine o illustrazione */}
    <div className="p-4 bg-transparent border border-gray-400 rounded-lg shadow-lg order-1 md:order-2">
      <div
        className="w-full h-80 bg-cover bg-center rounded-lg"
        style={{
          backgroundImage: `url(${require('../../assets/images/ISO.webp')})`,
        }}
      ></div>
    </div>
  </div>
</section>

{/* Le Normative Che Trattiamo */}
<section className="py-20 bg-[#070918] text-gray-300">
  <div className="max-w-7xl mx-auto px-6 md:px-12 text-center">
    {/* Titolo */}
    <h2 className="text-3xl md:text-4xl font-bold mb-8 text-yellow-500">
    I nostri programmi: Normative IT - Cybersecurity - Telco - AI
    </h2>
    {/* Testo introduttivo */}
    <p className="text-lg md:text-xl mb-12">
      Comprendere e rispettare le normative è fondamentale per la tua azienda. Qui trovi una panoramica delle principali regole che governano il tuo settore.
    </p>
    {/* Griglia delle Card */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* GDPR */}
      <div className="bg-[#111827] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-[#007bff] mb-2">GDPR</h3>
        <p className="text-gray-300">
          Protezione dei dati personali e privacy nell’Unione Europea.
        </p>
      </div>
      {/* ISO/IEC 27001 */}
      <div className="bg-[#111827] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-[#007bff] mb-2">ISO/IEC 27001</h3>
        <p className="text-gray-300">
          Standard per la sicurezza delle informazioni, essenziale per infrastrutture IT / ICT.
        </p>
      </div>
      {/* NIS Directive */}
      <div className="bg-[#111827] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-[#ff4d4f] mb-2">NIS Directive</h3>
        <p className="text-gray-300">
          Regolamenta la sicurezza delle reti e dei sistemi per infrastrutture critiche.
        </p>
      </div>
      {/* Cybersecurity Act */}
      <div className="bg-[#111827] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-[#ff4d4f] mb-2">Cybersecurity Ethical Hacking</h3>
        <p className="text-gray-300">
          Corso di sicurezza informatica, per una garanzia di qualità.
        </p>
      </div>
      {/* Artificial Intelligence Governance Professional */}
      <div className="bg-[#111827] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-[#28a745] mb-2">Artificial Intelligence Governance Professional (AIGP)</h3>
        <p className="text-gray-300">
          Framework per la gestione responsabile dell’intelligenza artificiale.
        </p>
      </div>
      {/* Artificial Intelligence Security Professional */}
      <div className="bg-[#111827] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-[#28a745] mb-2">Artificial Intelligence Security Professional (AISP)</h3>
        <p className="text-gray-300">
          Guida per la sicurezza delle applicazioni AI e la mitigazione dei rischi.
        </p>
      </div>
      {/* ETSI EN 303 645 */}
      <div className="bg-[#111827] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-[#ffd700] mb-2">ETSI EN 303 645</h3>
        <p className="text-gray-300">
          Standard per la sicurezza dei dispositivi IoT.
        </p>
      </div>
      {/* AI ACT */}
      <div className="bg-[#111827] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-[#28a745] mb-2">AI ACT</h3>
        <p className="text-gray-300">
          Regolamentazione Europea per un’intelligenza artificiale sicura e affidabile.
        </p>
      </div>
    </div>
    {/* CTA */}
    <div className="mt-28"></div>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Richiedi una Consulenza Gratuita
          </a>
  </div>
</section>

{/* I Nostri Programmi di Coaching */}
<section className="py-20 bg-gray-100 text-gray-800">
  <div className="max-w-7xl mx-auto px-6 md:px-12 text-center">
    {/* Titolo */}
    <h2 className="text-3xl md:text-4xl font-bold mb-8 text-blue-900">
      Formazione Su Misura per il Tuo Team
    </h2>
    {/* Testo introduttivo */}
    <p className="text-lg md:text-xl mb-12 text-gray-800">
      Ogni azienda è unica, così come le sue esigenze. I nostri programmi di coaching sono progettati per:
    </p>
    {/* Griglia di Card */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Card 1 */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-bold text-blue-900 mb-4">GDPR e Data Protection</h3>
        <p className="text-gray-700">
          Formazione sulla protezione dei dati personali.
        </p>
      </div>
      {/* Card 2 */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-bold text-blue-900 mb-4">Cybersecurity e ISO 27001</h3>
        <p className="text-gray-700">
          Strategie per la sicurezza aziendale.
        </p>
      </div>
      {/* Card 3 */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-bold text-blue-900 mb-4">AI Ethics e Sicurezza</h3>
        <p className="text-gray-700">
          Gestione responsabile dell’intelligenza artificiale.
        </p>
      </div>
      {/* Card 4 */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-bold text-blue-900 mb-4">Telco e IoT Security</h3>
        <p className="text-gray-700">
          Protezione di reti e dispositivi connessi.
        </p>
      </div>
    </div>
    {/* CTA */}
    <div className="text-center mt-16">
      <a
        href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

{/* Minimizzare i Rischi Aziendali e CTA Finale */}
      <section className="py-20 bg-gray-900 text-gray-300">
        <div className="max-w-6xl mx-auto px-6 md:px-12 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-yellow-500">Prevenzione e Sicurezza per la Tua Rete Aziendale</h2>
          <p className="text-lg md:text-xl text-gray-400 text-center mb-12">
          Navigare in sicurezza significa proteggere il tuo business. Ti insegniamo come:
        </p>
          <ul className="list-disc list-inside space-y-4 text-gray-400 text-left md:text-center">
            <li className="flex items-start gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="text-white"></span> Usare gli strumenti online senza correre rischi.</span>
            </li>
            <li className="flex items-start gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="text-white"></span> Prevenire attacchi di phishing e minacce informatiche.</span>
            </li>
            <li className="flex items-start gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="text-white"></span> Gestire password sicure e sistemi di autenticazione.</span>
            </li>
            <li className="flex items-start gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="text-white"></span> Garantire l’accesso controllato ai dati aziendali.</span>
            </li>
          </ul>
          <div className="mt-20">
            <h3 className="text-2xl font-bold text-yellow-500 mb-4">Pronto a Migliorare la Sicurezza della Tua Azienda?</h3>
            <p className="text-lg text-gray-400 mb-16">Affronta le normative e i rischi aziendali con fiducia. Scopri come possiamo aiutarti a formare il tuo team e proteggere il tuo business digitale.</p>
            <a
              href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Richiedi una Consulenza Gratuita
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Programmi;
