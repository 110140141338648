import React from 'react';
import ScrollToTopButton from '../../components/common/ScrollToTopButton'; // Pulsante di ritorno in alto
import CybersecurityImage from '../../assets/images/Scudo.webp'; // Immagine principale Hero
import CyberlockImage from '../../assets/images/Cyberlock.webp'; // Immagine principale del blocco
import MalwareImage from '../../assets/images/Malware.webp'; // Immagine principale del blocco
import ControlRoomImage from '../../assets/images/ControlRoom.webp'; // Immagine principale del blocco

const Cybersecurity = () => {
  return (
    <>
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${CybersecurityImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-700/50"></div>

        {/* Contenuto Hero */}
        <div className="relative z-10 text-white px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-16">
            Cybersecurity: Proteggi il Futuro della Tua Azienda
          </h1>
          <p className="text-lg md:text-2xl italic text-gray-100 mb-20">
            Soluzioni avanzate per proteggere reti, dati e infrastrutture aziendali da ogni minaccia informatica.
          </p>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Richiedi una Valutazione Gratuita
          </a>
        </div>
      </section>

      {/* Scroll To Top */}
      <ScrollToTopButton />

      {/* Blocco 2: Sicurezza delle Reti */}
      <section className="py-24 bg-gray-900 text-secondary">
      <div className="max-w-7xl mx-auto px-6 md:px-12">
        {/* Titolo e Descrizione */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-yellow-500 mb-8">
            Proteggi le Tue Reti con Sistemi Avanzati di Firewall e Monitoraggio
          </h2>
          <p className="text-xl text-gray-300 leading-relaxed mb-12">
            Implementiamo soluzioni di sicurezza per monitorare, prevenire e neutralizzare accessi non autorizzati, garantendo comunicazioni aziendali sicure.
          </p>
        </div>

        {/* Griglia dei Punti Chiave */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center mb-20">
          {/* Card 1: Firewall */}
          <div className="p-8 bg-black rounded-lg shadow-lg border border-red-500">
            
            <h3 className="text-2xl font-bold mb-4">Firewall di Ultima Generazione</h3>
            <p className="text-lg text-gray-300">
              Protezione delle comunicazioni interne ed esterne.
            </p>
          </div>

          {/* Card 2: IDS/IPS */}
          <div className="p-8 bg-black rounded-lg shadow-lg border border-red-500">
            
            <h3 className="text-2xl font-bold mb-4">Sistemi IDS/IPS</h3>
            <p className="text-lg text-gray-300">
              Rilevamento e prevenzione delle intrusioni in tempo reale.
            </p>
          </div>

          {/* Card 3: VLAN */}
          <div className="p-8 bg-black rounded-lg shadow-lg border border-red-500">
            
            <h3 className="text-2xl font-bold mb-4">Segmentazione delle Reti (VLAN)</h3>
            <p className="text-lg text-gray-300">
              Limitazione delle aree di rischio e miglioramento della gestione del traffico.
            </p>
          </div>
        </div>

        {/* CTA Locale */}
        <div className="text-center mt-20">
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Richiedi una Consulenza Gratuita
          </a>
        </div>
      </div>
    </section>

        {/* Blocco 3: Protezione dei Dati */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Sottotitolo */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Metti al Sicuro i Dati Sensibili della Tua Azienda
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed mb-12">
        I dati sono il patrimonio aziendale più prezioso. Offriamo soluzioni avanzate di backup crittografati, disaster recovery e protezione contro ransomware e malware.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Elenco puntato */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Backup e Disaster Recovery:</strong> Pianificazione di backup sicuri e ripristino rapido.</li>
          <li><strong>Crittografia Avanzata:</strong> Protezione dei dati in transito e in archiviazione.</li>
          <li><strong>Soluzioni Anti-Ransomware:</strong> Prevenzione e mitigazione degli attacchi.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${CyberlockImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

{/* Blocco 4: Test di Vulnerabilità e Penetration Testing */}
<section className="py-24 bg-gray-900 text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Identifica e Risolvi le Vulnerabilità Prima che lo Faccia un Attaccante
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Effettuiamo test approfonditi per individuare falle nella tua infrastruttura IT. Simuliamo attacchi reali per verificare la sicurezza dei sistemi e proporre soluzioni mirate.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Test di Vulnerabilità:</strong> Analisi e report dettagliati sulle aree a rischio.</li>
          <li><strong>Penetration Testing:</strong> Simulazione di attacchi per verificare la resistenza della rete.</li>
          <li><strong>Piani di Remediation:</strong> Soluzioni concrete per correggere le vulnerabilità rilevate.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${MalwareImage})`,
            filter: 'brightness(70%)',
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

{/* Blocco 5: Monitoraggio e Incident Response (SOC) */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Rimani Sempre un Passo Avanti con Monitoraggio Continuo e Risposta Rapida
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Grazie al nostro Security Operations Center (SOC), monitoriamo costantemente le infrastrutture aziendali, intervenendo tempestivamente per contenere e risolvere minacce informatiche.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Monitoraggio H24/7:</strong> Supervisione in tempo reale con alert automatici.</li>
          <li><strong>Incident Response:</strong> Interventi rapidi per bloccare e mitigare attacchi.</li>
          <li><strong>Reportistica e Analisi:</strong> Report periodici per identificare trend e rischi emergenti.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${ControlRoomImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

    {/* Blocco 6: CTA Finale */}
    <section className="py-24 bg-[#070918] text-secondary text-center">
      <div className="max-w-5xl mx-auto px-6 md:px-12">
        {/* Titolo */}
        <h3 className="text-4xl md:text-5xl font-bold mb-8">
          Vuoi Garantire la Massima Sicurezza per la Tua Azienda?
        </h3>
        {/* Testo */}
        <p className="text-xl leading-relaxed mb-12">
          Affida a GalileoSat la protezione delle tue reti, dei tuoi dati e delle tue infrastrutture IT. 
          La sicurezza non è un’opzione, è una necessità.
        </p>
        {/* Bottone CTA */}
        <a
          href="/contact"
          className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
        >
          Richiedi una Consulenza Gratuita
        </a>
      </div>
      {/* Linea Separatrice */}
      <div className="w-full h-[1px] bg-red-500 mt-20"></div>
    </section>
    </>
  );
};

export default Cybersecurity;
