import React from 'react';
import CybersecurityImage from '../../assets/images/Cybersecurity.webp';
import { FaCheck } from 'react-icons/fa';

const Security = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#070918] to-[#111827] text-gray-300">
      {/* Blocco Hero con Overlay */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${CybersecurityImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-900/80"></div>
        {/* Testo sopra l'overlay */}
        <div className="relative z-10 text-white px-6">
          <h1 className="text-4xl font-extrabold mb-20">Soluzioni Security: Proteggi la Tua Azienda da Ogni Minaccia</h1>
          <p className="text-lg md:text-2xl mb-8">
            Con GalileoSat, la sicurezza informatica non è un’opzione ma una priorità.
          </p>
          <p className="text-lg md:text-2xl mb-8">
            Scopri come affrontiamo le sfide di cybersecurity per garantire protezione, conformità e continuità operativa.
          </p>
          <div className="mt-28"></div>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
        </div>
      </section>

      {/* Il Nostro Approccio alla Sicurezza */}
    <section className="py-20 bg-gray-100 text-gray-800">
        <div className="max-w-7xl mx-auto text-center px-6 md:px-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-blue-900">La Strategia di GalileoSat per la Cybersecurity</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Fase 1 */}
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg border border-gray-500">
              <h3 className="text-xl font-bold text-blue-700 mb-5 text-center">1. Valutazione dei Rischi</h3>
              <p className="text-gray-700 text-left">
                Identifichiamo le vulnerabilità e valutiamo i rischi aziendali.
              </p>
            </div>
            {/* Fase 2 */}
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg border border-gray-500">
              <h3 className="text-xl font-bold text-blue-700 mb-4 text-center">2. Progettazione delle Soluzioni</h3>
              <p className="text-gray-700 text-left">
                Creiamo un piano personalizzato per proteggere reti, dati e sistemi.
              </p>
            </div>
            {/* Fase 3 */}
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg border border-gray-500">
              <h3 className="text-xl font-bold text-blue-700 mb-4 text-center">3. Implementazione delle Tecnologie</h3>
              <p className="text-gray-700 text-left">
                Installiamo firewall, IDS/IPS e soluzioni di protezione avanzate.
              </p>
            </div>
            {/* Fase 4 */}
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg border border-gray-500">
              <h3 className="text-xl font-bold text-blue-700 mb-4 text-center">4. Monitoraggio Continuo</h3>
              <p className="text-gray-700 text-left">
                Supervisione H24 per prevenire e rispondere agli incidenti.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center mt-16">
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
        </div>
      </section>

    {/* Case Study */}
    <section className="py-20 bg-gray-900 text-gray-300">
        <div className="max-w-6xl mx-auto px-6 md:px-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-yellow-500 text-center">
          Case Study: Reti Sicure per una Sala di Controllo Aerospaziale
          </h2>
          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-bold text-red-600 mb-4">La Sfida</h3>
              <p className="text-gray-300">
                Un'importante azienda del settore aerospaziale si trovava a fronteggiare una sfida critica: reti obsolete e vulnerabili, esposte a potenziali attacchi informatici e interruzioni operative. Questi rischi rappresentavano una minaccia concreta per la continuità delle operazioni, fondamentali per un settore così delicato.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-green-500 mb-4">La Nostra Soluzione</h3>
              <p className="text-gray-300">
                Per proteggere l’infrastruttura e garantire la massima affidabilità, il nostro team ha progettato e implementato una strategia di cybersecurity su misura. Tra le azioni principali:
              </p>
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                <li>Firewall di ultima generazione: installati e configurati per proteggere le reti da minacce esterne.</li>
                <li>Sistemi di rilevamento delle intrusioni (IDS/IPS): implementati per monitorare e rispondere tempestivamente ad attività sospette.</li>
                <li>Segmentazione VLAN bilanciata: creata per separare i dati sensibili operativi da quelli delle reti amministrative / logistiche, riducendo così il rischio di propagazione delle minacce.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-green-500 mb-4">I Risultati</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-400">
                <li>Riduzione del 90% delle vulnerabilità di rete: un miglioramento significativo nella sicurezza complessiva.</li>
                <li>Tempo di risposta agli incidenti dimezzato: maggiore efficienza nella gestione degli imprevisti.</li>
                <li>Continuità operativa garantita: tutte le implementazioni sono avvenute senza interruzioni per le operazioni critiche.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center mt-16">
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
        </div>
      </section>

    {/* Soluzioni Tecniche Specifiche */}
      <section className="py-20 bg-gray-100 text-gray-800">
        <div className="max-w-7xl mx-auto text-center px-6 md:px-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-blue-800">Le Tecnologie che Utilizziamo per Proteggerti</h2>
          <p className="text-lg md:text-xl text-gray-700 mb-12">
          Le nostre soluzioni avanzate di sicurezza si basano su tecnologie all'avanguardia e strumenti progettati per garantire la massima protezione e continuità operativa. Ecco come lavoriamo per proteggere i tuoi dati e la tua azienda:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Tecnologie 1 */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Firewall di ultima generazione e IDS/IPS</h3>
              <p className="text-gray-700 text-left">
              Blocco proattivo delle minacce informatiche con una protezione avanzata contro intrusioni, attacchi esterni e attività sospette.
              </p>
            </div>
            {/* Tecnologie 2 */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Segmentazione VLAN intelligente</h3>
              <p className="text-gray-700 text-left">
              Isolamento dei dati critici per prevenire accessi non autorizzati e migliorare la resilienza della rete.
              </p>
            </div>
            {/* Tecnologie 3 */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Crittografia avanzata e VPN sicure</h3>
              <p className="text-gray-700 text-left">
              Garantiamo comunicazioni protette per utenti remoti e sistemi interconnessi, salvaguardando la privacy e l’integrità dei dati.
              </p>
            </div>
            {/* Tecnologie 4 */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Backup affidabili e Disaster Recovery tempestivo</h3>
              <p className="text-gray-700 text-left">
              Proteggiamo i tuoi dati da qualsiasi imprevisto, assicurando un ripristino rapido e la continuità del tuo business anche in caso di incidente.
              </p>
            </div>
          </div>
          <div className="text-center mt-20">
            <a
              href="/contact"
              className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Contattaci Ora
            </a>
          </div>
        </div>
      </section>

    {/* Conformità Normativa e Consulenza Strategica e CTA Finale */}
      <section className="py-20 bg-gray-900 text-gray-300">
        <div className="max-w-6xl mx-auto px-6 md:px-12 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-yellow-500">Garantiamo Conformità alle Normative e Sicurezza dei Dati</h2>
          <ul className="list-disc list-inside space-y-4 text-gray-400 text-left md:text-center">
            <li className="flex items-start gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="font-bold text-white">Conformità al GDPR e ISO 27001:</span> Garantiamo che le infrastrutture IT rispettino le leggi sulla protezione dei dati.</span>
            </li>
            <li className="flex items-start gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="font-bold text-white">Auditing e Penetration Testing:</span> Verifica della sicurezza e individuazione delle vulnerabilità.</span>
            </li>
            <li className="flex items-start gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="font-bold text-white">Piani di Remediation:</span> Soluzioni concrete per risolvere problemi identificati durante gli audit.</span>
            </li>
          </ul>
          <div className="mt-20">
            <h3 className="text-2xl font-bold text-yellow-500 mb-4">Rendi la tua azienda conforme e sicura con il nostro supporto</h3>
            <p className="text-lg text-gray-400 mb-16">Siamo pronti a progettare la soluzione perfetta per garantire la tua conformità e sicurezza IT.</p>
            <a
              href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Richiedi una Consulenza Gratuita
            </a>
          </div>
        </div>
      </section>
     
    </div>
  );
};

export default Security;