import React from 'react';
import { useNavigate } from 'react-router-dom';

const GDPRGuide = () => {
  const navigate = useNavigate(); // Hook per navigare tra le pagine
  return (
    <div className="min-h-screen bg-[#070918] py-12">
      {/* Titolo dell'articolo */}
      <h1 className="text-4xl font-bold text-center text-white mb-6">
        Come rispettare il GDPR: guida per PMI
      </h1>
      {/* Data di pubblicazione */}
      <p className="text-center text-gray-400 mb-12">
        Pubblicato il 10 Dicembre 2024
      </p>
      {/* Contenuto dell'articolo */}
      <div className="max-w-4xl mx-auto px-6 article-content leading-relaxed">
        <p>
          Il Regolamento Generale sulla Protezione dei Dati (GDPR) è una normativa cruciale per tutte le aziende
          che trattano dati personali nell’Unione Europea. Sebbene complesso, il GDPR è essenziale per proteggere
          la privacy degli utenti e prevenire multe salate.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Passaggi chiave per la conformità:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Mappare i dati:</strong> Identificare quali dati personali vengono raccolti, dove sono
            archiviati e come vengono utilizzati.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Informare gli utenti:</strong> Redigere una privacy policy chiara e accessibile, specificando
            come vengono trattati i loro dati.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Ottenere il consenso:</strong> Garantire che il consenso sia libero, specifico e informato,
            soprattutto per l’invio di comunicazioni di marketing.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Implementare misure di sicurezza:</strong> Proteggere i dati attraverso crittografia,
            backup regolari e controlli di accesso.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Gestire le richieste degli utenti:</strong> Creare procedure per gestire richieste di accesso,
            rettifica o cancellazione dei dati personali.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Benefici per le PMI:</h2>
        <p>
          Oltre a evitare sanzioni, la conformità al GDPR migliora la fiducia dei clienti e rafforza la reputazione
          aziendale.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Conclusione:</h2>
        <p>
          Rispettare il GDPR non è solo un obbligo legale, ma un investimento nella sicurezza e nella trasparenza
          che può portare vantaggi competitivi.
        </p>
      </div>
    {/* Pulsante per tornare alla pagina Blog */}
    <div className="text-center mt-12">
        <button
          onClick={() => navigate('/blog')}
          className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
        >
          Torna al Blog
        </button>
      </div>
    </div>
  );
};

export default GDPRGuide;
