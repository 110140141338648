import React from 'react';
import { useNavigate } from 'react-router-dom';

const UseCaseGuide = () => {
  const navigate = useNavigate(); // Hook per navigare tra le pagine
  return (
    <div className="min-h-screen bg-[#070918] py-12">
      {/* Titolo dell'articolo */}
      <h1 className="text-4xl font-bold text-center text-white mb-6">
        Case Study: come abbiamo ottimizzato la rete di un’azienda manifatturiera
      </h1>
      {/* Data di pubblicazione */}
      <p className="text-center text-gray-400 mb-12">
        Pubblicato il 10 Ottobre 2024
      </p>
      {/* Contenuto dell'articolo */}
      <div className="max-w-4xl mx-auto px-6 article-content leading-relaxed">
        <p>
          Le aziende manifatturiere si trovano spesso ad affrontare sfide legate alla gestione delle reti aziendali,
          che devono supportare sia i processi produttivi che le operazioni quotidiane. Un nostro cliente, una media
          impresa nel settore della meccanica, si trovava di fronte a rallentamenti frequenti e problemi di sicurezza
          nella sua rete.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">La sfida:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>Velocità di rete insufficiente per supportare le macchine connesse.</li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>Mancanza di segmentazione del traffico, che aumentava il rischio di sovraccarichi.</li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>Assenza di soluzioni per monitorare e prevenire le minacce informatiche.</li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">La soluzione:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Bilanciamento del carico:</strong> Installazione di un sistema per distribuire uniformemente il
            traffico tra i dispositivi.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Segmentazione VLAN:</strong> Creazione di reti virtuali per separare i flussi di traffico critico
            da quelli meno prioritari.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Firewall avanzati:</strong> Introduzione di protezioni per bloccare minacce esterne e controllare
            gli accessi interni.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Monitoraggio in tempo reale:</strong> Configurazione di strumenti per rilevare anomalie e intervenire
            rapidamente.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">I risultati:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Miglioramento delle prestazioni:</strong> Velocità di rete aumentata del 45%, garantendo operazioni
            fluide.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Maggiore sicurezza:</strong> Riduzione del 70% degli attacchi informatici bloccati.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Efficienza operativa:</strong> Tempi di inattività ridotti, con un aumento della produttività
            del 30%.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Conclusione:</h2>
        <p>
          Questo caso dimostra come un’ottimizzazione mirata della rete possa trasformare le operazioni di un’azienda,
          migliorando sia la sicurezza che l’efficienza produttiva.
        </p>
      </div>
    {/* Pulsante per tornare alla pagina Blog */}
    <div className="text-center mt-12">
        <button
          onClick={() => navigate('/blog')}
          className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
        >
          Torna al Blog
        </button>
      </div>
    </div>
  );
};

export default UseCaseGuide;
