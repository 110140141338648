import React from 'react';
import { useNavigate } from 'react-router-dom';

const VlanGuide = () => {
  const navigate = useNavigate(); // Hook per navigare tra le pagine
  return (
    <div className="min-h-screen bg-[#070918] py-12">
      {/* Titolo dell'articolo */}
      <h1 className="text-4xl font-bold text-center text-white mb-6">
        VLAN: perché sono essenziali per la sicurezza e l’efficienza
      </h1>
      {/* Data di pubblicazione */}
      <p className="text-center text-gray-400 mb-12">
        Pubblicato il 25 Novembre 2024
      </p>
      {/* Contenuto dell'articolo */}
      <div className="max-w-4xl mx-auto px-6 article-content leading-relaxed">
        <p>
          Le VLAN (Virtual Local Area Network) sono uno degli strumenti più utili per organizzare e ottimizzare
          le reti aziendali. Consentono di segmentare il traffico di rete, isolando gruppi di dispositivi in modo
          virtuale anche se fisicamente collegati alla stessa infrastruttura.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Cosa sono le VLAN?</h2>
        <p>
          Una VLAN è una rete logica che consente di separare il traffico di diversi dispositivi sulla stessa
          infrastruttura fisica. Ad esempio, è possibile isolare i dipendenti di un dipartimento da quelli di un altro,
          migliorando sicurezza e prestazioni.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Benefici delle VLAN:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Miglioramento della sicurezza:</strong> La segmentazione riduce il rischio di accessi non autorizzati
            e limita l'impatto di eventuali attacchi.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Ottimizzazione del traffico:</strong> Separare i flussi di dati evita congestioni, migliorando le
            prestazioni della rete.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Flessibilità e gestione semplificata:</strong> È possibile riorganizzare le risorse di rete senza
            dover modificare il cablaggio fisico.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Esempi di utilizzo:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Separazione tra rete amministrativa e ospiti:</strong> Garantisce che i visitatori non abbiano
            accesso a dati aziendali sensibili.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Isolamento di servizi critici:</strong> Protegge server e dispositivi essenziali da potenziali
            minacce.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Ottimizzazione del lavoro remoto:</strong> Le VLAN consentono connessioni sicure per i dipendenti
            che lavorano da casa.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Come configurare una VLAN efficace:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Pianificazione:</strong> Identificare i gruppi di dispositivi che necessitano di segmentazione.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Configurazione degli switch:</strong> La maggior parte degli switch di rete moderni supporta VLAN
            e consente una configurazione semplice.
          </li>
        </ul>
        <p className="mb-6"></p>

        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Monitoraggio e manutenzione:</strong> Assicurarsi che la rete sia monitorata per garantire che le
            configurazioni siano aggiornate e sicure.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Conclusione:</h2>
        <p>
          Le VLAN sono un pilastro fondamentale per la sicurezza e l'efficienza delle reti aziendali. La loro
          implementazione permette di ridurre i rischi, migliorare le prestazioni e semplificare la gestione
          dell'infrastruttura.
        </p>
      </div>
    {/* Pulsante per tornare alla pagina Blog */}
    <div className="text-center mt-12">
        <button
          onClick={() => navigate('/blog')}
          className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
        >
          Torna al Blog
        </button>
      </div>
    </div>
  );
};

export default VlanGuide;
