// GNSS.jsx
import React from 'react';
import GalileoImage from '../../assets/images/Galileo.webp';
import { FaCheck } from 'react-icons/fa';

const GNSS = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#070918] to-[#111827] text-gray-300">
      {/* Blocco Hero con Overlay */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${GalileoImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-900/80"></div>
        {/* Testo sopra l'overlay */}
        <div className="relative z-10 text-white px-6">
          <h1 className="text-4xl font-extrabold mb-20">Soluzioni Satellitari: Tecnologia Spaziale al Servizio della Tua Azienda</h1>
          <p className="text-lg md:text-2xl mb-4">
            Con GalileoSat, utilizziamo dati GNSS e sistemi satellitari per migliorare l’efficienza, la sicurezza e la gestione delle risorse aziendali.
          </p>
          <p className="text-lg md:text-2xl mb-8">
            Scopri come trasformiamo dati satellitari in soluzioni concrete.
          </p>
          <div className="mt-28"></div>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
        </div>
      </section>

    {/* Come GalileoSat Trasforma i Dati Satellitari in Soluzioni Aziendali */}
      <section className="py-20 bg-gray-100 text-gray-800">
        <div className="max-w-7xl mx-auto px-6 md:px-12 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-blue-900">Come GalileoSat Trasforma i Dati Satellitari in Soluzioni Aziendali</h2>
          <p className="text-lg md:text-xl text-gray-700 mb-12">
            Il nostro approccio innovativo ai dati satellitari combina tecnologia avanzata e analisi strategica per trasformare informazioni complesse in opportunità di crescita per il tuo business. Ecco come operiamo:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Raccolta dei Dati */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Raccolta dei Dati</h3>
              <p className="text-gray-700 text-left">
                Integriamo dati GNSS, telemetria e monitoraggio remoto per costruire una base solida e completa, offrendo una visione multidimensionale su scala globale.
              </p>
            </div>
            {/* Elaborazione dei Dati */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Elaborazione dei Dati</h3>
              <p className="text-gray-700 text-left">
                Utilizziamo tecniche avanzate di analisi per estrarre insight significativi, fornendo informazioni preziose per decisioni aziendali più rapide e accurate.
              </p>
            </div>
            {/* Implementazione delle Soluzioni */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Implementazione delle Soluzioni</h3>
              <p className="text-gray-700 text-left">
                Trasformiamo i dati in applicazioni pratiche per settori chiave come logistica, monitoraggio ambientale e gestione delle risorse, creando valore tangibile per i nostri clienti.
              </p>
            </div>
            {/* Ottimizzazione Continua */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Ottimizzazione Continua</h3>
              <p className="text-gray-700 text-left">
                Monitoriamo costantemente le soluzioni implementate, migliorando i risultati nel tempo grazie a un processo iterativo e adattivo.
              </p>
            </div>
          </div>
          <div className="mt-16">
            <h3 className="text-2xl font-bold text-blue-900 mb-4">Perché Scegliere GalileoSat?</h3>
            <p className="text-lg text-gray-700">
              Con GalileoSat i dati satellitari non sono solo numeri: diventano strumenti strategici per migliorare l'efficienza, ridurre i costi e favorire l’innovazione nei tuoi processi aziendali. Affidati alla nostra esperienza per un futuro connesso e sostenibile.
            </p>
            <div className="mt-16"></div>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
          </div>
        </div>
      </section>

      {/* Case Study: Ottimizzazione della Catena del Freddo con Dati GNSS */}
      <section className="py-20 bg-gray-900 text-gray-300">
        <div className="max-w-6xl mx-auto px-6 md:px-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-yellow-500 text-center">
            Case Study: Ottimizzazione della Catena del Freddo con Dati GNSS
          </h2>
          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-bold text-red-600 mb-4">La Sfida</h3>
              <p className="text-gray-300">
                Un’importante azienda del settore agroalimentare affrontava una problematica critica: garantire il monitoraggio in tempo reale della temperatura durante il trasporto di merci sensibili. La mancanza di controllo poteva causare sprechi significativi, danni alla qualità del prodotto e non conformità alle normative di trasporto.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-green-500 mb-4">La Nostra Soluzione</h3>
              <p className="text-gray-300">
                Per rispondere a questa sfida, abbiamo progettato e implementato una soluzione integrata basata su:
              </p>
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                <li>Dati GNSS: per il tracciamento preciso in tempo reale della posizione dei veicoli.</li>
                <li>Sensori IoT avanzati: per monitorare costantemente la temperatura e le condizioni delle merci durante il trasporto.</li>
                <li>Segnalazioni automatiche: per avvisare immediatamente in caso di anomalie, permettendo interventi rapidi e mirati.</li>
              </ul>
              <p className="text-gray-300 mt-4">
                Grazie a questa tecnologia, l’azienda è stata in grado di ottimizzare la gestione della catena del freddo e garantire la massima qualità delle sue merci.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-green-500 mb-4">I Risultati</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-400">
                <li>Riduzione degli sprechi del 30% grazie a un controllo più efficiente delle condizioni di trasporto.</li>
                <li>Miglioramento della conformità normativa, assicurando il rispetto degli standard di sicurezza alimentare.</li>
                <li>Monitoraggio continuo e reattivo, con una gestione proattiva degli incidenti e un miglioramento dell’efficienza operativa.</li>
              </ul>
            </div>
          </div>
          <div className="text-center mt-16">
            <a
              href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Contattaci Ora
            </a>
          </div>
        </div>
      </section>

      {/* Le Tecnologie Satellitari che Utilizziamo */}
      <section className="py-20 bg-gray-100 text-gray-800">
        <div className="max-w-7xl mx-auto px-6 md:px-12 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-blue-900">Le Tecnologie Satellitari che Utilizziamo</h2>
          <p className="text-lg md:text-xl text-gray-700 mb-12">
            Le nostre soluzioni satellitari si basano su tecnologie di ultima generazione, progettate per garantire precisione, affidabilità e valore aggiunto. Scopri come trasformiamo i dati satellitari in strumenti strategici per il tuo business:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* GNSS Avanzato */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">GNSS Avanzato</h3>
              <p className="text-gray-700">
                Forniamo una geolocalizzazione estremamente precisa, ideale per il monitoraggio continuo di asset e risorse in qualsiasi parte del mondo.
              </p>
            </div>
            {/* Monitoraggio Remoto */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Monitoraggio Remoto</h3>
              <p className="text-gray-700">
                Attraverso sensori e sistemi di telemetria, rileviamo in tempo reale le condizioni ambientali e operative, offrendo una supervisione costante e proattiva.
              </p>
            </div>
            {/* Integrazione IoT-Satellite */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Integrazione IoT-Satellite</h3>
              <p className="text-gray-700">
                Combiniamo dispositivi IoT con la potenza dei sistemi satellitari, permettendo la raccolta e l’elaborazione di dati complessi da ambienti remoti e difficilmente accessibili.
              </p>
            </div>
            {/* Analisi Avanzata dei Dati Satellitari */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
              <h3 className="text-xl font-bold text-blue-700 mb-4">Analisi Avanzata dei Dati Satellitari</h3>
              <p className="text-gray-700">
                Utilizziamo algoritmi di analisi avanzati per trasformare i dati grezzi in insight pratici, aiutandoti a prendere decisioni più informate e strategiche.
              </p>
            </div>
          </div>
          <div className="text-center mt-16">
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
        </div>
        </div>
      </section>

      {/* Dove Utilizziamo i Dati Satellitari */}
<section className="py-20 bg-gray-900 text-gray-300">
  <div className="max-w-6xl mx-auto px-6 md:px-12">
    <h2 className="text-3xl md:text-4xl font-bold mb-12 text-yellow-500 text-center">
      Dove Utilizziamo i Dati Satellitari
    </h2>
    <p className="text-lg md:text-xl text-gray-400 text-center mb-12">
      Le nostre soluzioni satellitari trovano applicazione in una vasta gamma di settori, trasformando dati complessi in vantaggi pratici. Ecco alcune delle principali aree in cui facciamo la differenza:
    </p>
    <ul className="list-disc list-inside space-y-8 text-gray-400 text-left">
      <li className="flex items-start gap-2">
        <FaCheck className="text-green-500" />
        <span>
          <span className="font-bold text-white">Logistica e Flotte:</span> Tracciamo i veicoli in tempo reale e ottimizziamo i percorsi, riducendo costi operativi e migliorando l'efficienza delle consegne.
        </span>
      </li>
      <li className="flex items-start gap-2">
        <FaCheck className="text-green-500" />
        <span>
          <span className="font-bold text-white">Monitoraggio Ambientale:</span> Analizziamo parametri come temperatura, umidità e vibrazioni, offrendo dati precisi per la gestione di condizioni critiche.
        </span>
      </li>
      <li className="flex items-start gap-2">
        <FaCheck className="text-green-500" />
        <span>
          <span className="font-bold text-white">Manutenzione Predittiva:</span> Identifichiamo i guasti prima che si verifichino, minimizzando tempi di inattività e costi imprevisti.
        </span>
      </li>
      <li className="flex items-start gap-2">
        <FaCheck className="text-green-500" />
        <span>
          <span className="font-bold text-white">Gestione delle Risorse:</span> Forniamo monitoraggio remoto per asset in aree difficili da raggiungere, assicurando supervisione continua e riducendo i rischi.
        </span>
      </li>
    </ul>
    <div className="text-center mt-16">
      <h3 className="text-2xl font-bold text-yellow-500 mb-4">
        Pronto a Sfruttare i Dati Satellitari per la Tua Azienda?
      </h3>
      <p className="text-lg text-gray-400 mb-8">
        I dati satellitari non sono solo tecnologia: sono la chiave per ottimizzare le operazioni aziendali, aumentare l'efficienza e innovare i tuoi processi. Scopri come possiamo creare soluzioni personalizzate per le tue esigenze.
      </p>
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Contattaci Ora
      </a>
    </div>
  </div>
</section>
    </div>
  );
};

export default GNSS;
