import React from 'react';
import GNSSImage from '../../assets/images/GNSS.webp'; // Immagine principale Hero
import Monitoring2Image from '../../assets/images/Monitoring2.webp'; // Immagine principale del blocco
import TrackingImage from '../../assets/images/Tracking.webp'; // Immagine principale del blocco
import ControlRoomImage from '../../assets/images/ControlRoom2.webp'; // Immagine principale del blocco

const Satcom = () => {
  return (
    <>
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${GNSSImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-700/50"></div>

        {/* Contenuto Hero */}
        <div className="relative z-10 text-white px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-16">
            Satcom: Comunicazioni Satellitari per una Connettività Globale e Affidabile
          </h1>
          <p className="text-lg md:text-2xl italic text-gray-100 mb-20">
            Scopri le nostre soluzioni di comunicazione satellitare per garantire connessioni stabili e sicure, anche nelle aree più remote.
          </p>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Richiedi una Valutazione Gratuita
          </a>
        </div>
      </section>

      {/* Blocco 2: Connettività Satellitare */}
      <section className="py-24 bg-gray-900 text-secondary">
        <div className="max-w-7xl mx-auto px-6 md:px-12">
          {/* Titolo e Descrizione */}
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-yellow-500 mb-8">
              Connessioni Ovunque Grazie ai Sistemi Satcom
            </h2>
            <p className="text-xl text-gray-300 leading-relaxed mb-12">
              Le nostre soluzioni di connettività satellitare assicurano comunicazioni stabili e veloci, anche in aree prive di infrastrutture terrestri. Ideali per aziende che operano in località remote o in settori critici.
            </p>
          </div>

          {/* Griglia dei Punti Chiave */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center mb-16">
            {/* Card 1: Banda Larga Satellitare */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-green-500">
              <div className="mb-4">
                <span className="text-4xl text-green-500">📡</span> {/* Icona testuale */}
              </div>
              <h3 className="text-2xl font-bold mb-4">Banda Larga Satellitare</h3>
              <p className="text-lg text-gray-300">
                Connessioni veloci per applicazioni aziendali e industriali.
              </p>
            </div>

            {/* Card 2: Copertura Globale */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-green-500">
              <div className="mb-4">
                <span className="text-4xl text-green-500">🌍</span> {/* Icona testuale */}
              </div>
              <h3 className="text-2xl font-bold mb-4">Copertura Globale</h3>
              <p className="text-lg text-gray-300">
                Comunicazioni affidabili anche nelle zone più isolate.
              </p>
            </div>

            {/* Card 3: Soluzioni M2M */}
            <div className="p-8 bg-black rounded-lg shadow-lg border border-green-500">
              <div className="mb-4">
                <span className="text-4xl text-green-500">🔗</span> {/* Icona testuale */}
              </div>
              <h3 className="text-2xl font-bold mb-4">Soluzioni M2M</h3>
              <p className="text-lg text-gray-300">
                Connessione tra dispositivi per applicazioni industriali e IoT.
              </p>
            </div>
          </div>

          {/* CTA Locale */}
          <div className="text-center mt-12">
            <a
              href="/contact"
              className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Richiedi una Valutazione Gratuita
            </a>
          </div>
        </div>
      </section>

      {/* Blocco 3: Comunicazioni Sicure e Critiche */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Sicurezza e Continuità per le Comunicazioni Critiche
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Le comunicazioni satellitari offrono un livello superiore di sicurezza, garantendo continuità operativa in contesti critici come emergenze, operazioni militari e gestione delle infrastrutture strategiche.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Ridondanza delle Comunicazioni:</strong> Backup satellitare per garantire la continuità anche in caso di guasti terrestri.</li>
          <li><strong>Crittografia Avanzata:</strong> Protezione dei dati trasmessi tramite protocolli sicuri.</li>
          <li><strong>Gestione di Emergenze:</strong> Comunicazioni stabili durante disastri naturali o situazioni di crisi.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${Monitoring2Image})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

{/* Blocco 4: Monitoraggio e Telemetria */}
<section className="py-24 bg-gray-900 text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Dati in Tempo Reale Grazie alla Telemetria Satellitare
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Le soluzioni di telemetria basate su Satcom ti consentono di monitorare e controllare risorse remote in tempo reale, raccogliendo dati essenziali per ottimizzare i processi aziendali.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Monitoraggio Remoto:</strong> Sensori connessi per rilevare prestazioni e condizioni ambientali.</li>
          <li><strong>Gestione IoT Satellitare:</strong> Integrazione di dispositivi IoT per applicazioni in zone senza rete terrestre.</li>
          <li><strong>Raccolta e Analisi dei Dati:</strong> Informazioni critiche per decisioni operative e strategiche.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${TrackingImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

{/* Blocco 5: Integrazione e Supporto Satcom */}
<section className="py-24 bg-[#070918] text-secondary">
  <div className="max-w-7xl mx-auto px-6 md:px-12">
    {/* Titolo e Descrizione */}
    <div className="mb-12">
      <h2 className="text-4xl md:text-4xl font-bold text-yellow-500 mb-8">
        Integrazione Personalizzata e Supporto Continuo
      </h2>
      <p className="text-xl text-gray-300 leading-relaxed">
        Offriamo soluzioni Satcom integrate, progettate per adattarsi perfettamente alle esigenze della tua azienda. Il nostro team garantisce supporto tecnico costante e manutenzione per massimizzare le prestazioni.
      </p>
    </div>

    {/* Contenuto con Immagine */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Punti Chiave */}
      <div className="p-6 bg-transparent rounded-lg shadow-lg">
        <ul className="list-disc pl-5 text-lg text-gray-300 space-y-4">
          <li><strong>Progettazione su Misura:</strong> Soluzioni scalabili per ogni contesto aziendale.</li>
          <li><strong>Manutenzione Programmata:</strong> Assistenza proattiva per garantire continuità operativa.</li>
          <li><strong>Supporto H24:</strong> Un team di esperti sempre a disposizione.</li>
        </ul>
      </div>

      {/* Immagine */}
      <div className="p-6 bg-transparent border border-gray-700 rounded-lg shadow-lg">
        <div
          className="w-full h-80 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${ControlRoomImage})`,
          }}
        ></div>
      </div>
    </div>

    {/* CTA Locale */}
    <div className="text-center mt-20">
      <a
        href="/contact"
        className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
      >
        Richiedi una Consulenza Gratuita
      </a>
    </div>
  </div>
</section>

    {/* Blocco 6: CTA Finale */}
    <section className="py-24 bg-[#070918] text-secondary text-center">
      <div className="max-w-5xl mx-auto px-6 md:px-12">
        {/* Titolo */}
        <h3 className="text-4xl md:text-5xl font-bold mb-8">
          Pronto a Trasformare le Tue Comunicazioni con Soluzioni Satcom?
        </h3>
        {/* Testo */}
        <p className="text-xl leading-relaxed mb-12">
          Contattaci per scoprire come GalileoSat può migliorare la tua connettività, proteggere le tue comunicazioni e ottimizzare i tuoi processi aziendali.
        </p>
        {/* Bottone CTA */}
        <a
          href="/contact"
          className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
        >
          Richiedi una Consulenza Gratuita
        </a>
      </div>
      {/* Linea Separatrice */}
      <div className="w-full h-[1px] bg-green-500 mt-20"></div>
    </section>
    </>
  );
};

export default Satcom;


