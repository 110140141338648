import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container bg-[#070918] text-gray-300 p-8">
      <h1 className="text-4xl font-bold text-center text-yellow-500 mb-8">Privacy Policy di GalileoSat</h1>
      <p className="mb-4 text-sm italic text-center">Ultimo aggiornamento: 08/01/2025</p>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">1. Introduzione</h2>
        <p>
          GalileoSat si impegna a proteggere la privacy degli utenti e garantire che i loro dati personali siano trattati in
          conformità alle normative vigenti, tra cui il Regolamento Generale sulla Protezione dei Dati (GDPR - Regolamento UE 2016/679).
          Questa Privacy Policy descrive come raccogliamo, utilizziamo e proteggiamo i tuoi dati personali durante l'uso del nostro sito
          web www.galileosat.com.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">2. Chi è il Titolare del Trattamento dei Dati?</h2>
        <p>
          Il titolare del trattamento dei dati personali è:
        </p>
        <p className="ml-4">
          <strong>GalileoSat</strong> <br />
          Sede legale: Via Bassa Arzergrande (Pd) <br />
          Email: <a href="mailto:info@galileosat.com" className="text-blue-500 underline">info@galileosat.com</a>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">3. Quali Dati Raccogliamo?</h2>
        <h3 className="text-xl font-bold text-white mb-4">Dati che Raccogliamo Direttamente</h3>
        <ul className="list-disc ml-6 mb-4">
          <li>Dati identificativi: Nome, cognome, email, numero di telefono.</li>
          <li>Dati forniti tramite form: Informazioni raccolte durante la compilazione dei form di contatto o di richiesta di consulenza.</li>
        </ul>

        <h3 className="text-xl font-bold text-white mb-4">Dati Raccolti Automaticamente</h3>
        <ul className="list-disc ml-6 mb-4">
          <li>Dati tecnici: Indirizzo IP, tipo di dispositivo, sistema operativo, browser.</li>
          <li>Cookie: Informazioni sulle preferenze di navigazione. (Consulta la nostra <a href="/cookie" className="text-blue-500 underline">Cookie Policy</a> per maggiori dettagli.)</li>
        </ul>

        <h3 className="text-xl font-bold text-white mb-4">Dati Sensibili</h3>
        <p>GalileoSat non raccoglie dati sensibili (es. relativi alla salute o credenze religiose) tramite il sito web.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">4. Perché Raccogliamo i Tuoi Dati?</h2>
        <ul className="list-disc ml-6">
          <li>Fornire i nostri servizi: Rispondere a richieste di consulenza, supporto e informazioni.</li>
          <li>Migliorare il nostro sito: Analizzare il traffico e ottimizzare l’esperienza utente.</li>
          <li>Finalità di marketing: Inviare comunicazioni promozionali solo previo consenso esplicito.</li>
          <li>Conformità normativa: Adempiere agli obblighi legali e fiscali.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">5. Su Quale Base Giuridica Trattiamo i Dati?</h2>
        <p>Il trattamento dei dati personali si basa sulle seguenti basi giuridiche:</p>
        <ul className="list-disc ml-6">
          <li><strong>Esecuzione di un contratto:</strong> Quando il trattamento è necessario per fornire un servizio richiesto dall’utente.</li>
          <li><strong>Consenso:</strong> Per attività di marketing o raccolta di dati non strettamente necessari.</li>
          <li><strong>Obblighi legali:</strong> Quando richiesto dalla legge (es. conservazione dei dati fiscali).</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">6. Condivisione dei Dati con Terze Parti</h2>
        <p>I dati personali raccolti possono essere condivisi con:</p>
        <ul className="list-disc ml-6">
          <li>Fornitori di servizi tecnici: Hosting, analisi del traffico (es. Google Analytics).</li>
          <li>Autorità competenti: Solo in caso di obblighi legali o richieste ufficiali.</li>
        </ul>
        <p>I dati non saranno mai venduti o condivisi con terze parti per scopi non dichiarati.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">7. Per Quanto Tempo Conserviamo i Dati?</h2>
        <ul className="list-disc ml-6">
          <li><strong>Dati raccolti per fornire servizi:</strong> Conservati per 5 anni dopo la chiusura del rapporto.</li>
          <li><strong>Dati di marketing:</strong> Conservati fino a revoca del consenso.</li>
          <li><strong>Dati tecnici e di navigazione:</strong> Conservati per un massimo di 12 mesi.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">8. I Tuoi Diritti</h2>
        <p>In conformità al GDPR, hai il diritto di:</p>
        <ul className="list-disc ml-6">
          <li>Accedere ai tuoi dati personali: Richiedere una copia dei dati che abbiamo raccolto.</li>
          <li>Correggere i tuoi dati: Richiedere la modifica di informazioni errate o incomplete.</li>
          <li>Cancellare i tuoi dati: Richiedere la rimozione dei dati personali (diritto all'oblio).</li>
          <li>Limitare il trattamento: In determinate circostanze, puoi limitare l’uso dei tuoi dati.</li>
          <li>Revocare il consenso: Ritirare il consenso per attività di marketing in qualsiasi momento.</li>
          <li>Proporre reclamo: Contattare l’autorità garante per la protezione dei dati personali se ritieni che i tuoi diritti siano stati violati.</li>
        </ul>
        <p>Per esercitare i tuoi diritti, invia una richiesta a <a href="mailto:info@galileosat.com" className="text-blue-500 underline">info@galileosat.com</a>.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">9. Sicurezza dei Dati</h2>
        <p>Utilizziamo tecnologie avanzate per garantire la sicurezza dei dati personali:</p>
        <ul className="list-disc ml-6">
          <li>Crittografia: Protezione dei dati durante la trasmissione.</li>
          <li>Firewall e IDS: Protezione contro accessi non autorizzati.</li>
          <li>Accesso Limitato: I dati sono accessibili solo a personale autorizzato.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">10. Aggiornamenti della Privacy Policy</h2>
        <p>
          Ci riserviamo il diritto di aggiornare questa Privacy Policy per riflettere eventuali modifiche normative o operative.
          Ti invitiamo a consultare periodicamente questa pagina per rimanere informato.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-4">11. Contatti</h2>
        <p>
          Se hai domande o dubbi riguardanti questa Privacy Policy, contattaci:
        </p>
        <p>Email: <a href="mailto:info@galileosat.com" className="text-blue-500 underline">info@galileosat.com</a></p>
      </section>

      {/* English Block */}
      <div className="bg-white text-gray-800 p-6 rounded-md shadow-md">
  <h1 className="text-4xl font-bold text-center text-gray-900 mb-8">Privacy Policy of GalileoSat</h1>
  <p className="mb-4 text-sm italic text-center">Last updated: 08/01/2025</p>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Introduction</h2>
  <p>
    GalileoSat is committed to protecting users' privacy and ensuring that their personal data is handled in
    compliance with applicable regulations, including the General Data Protection Regulation (GDPR - EU Regulation 2016/679).
    This Privacy Policy describes how we collect, use, and safeguard your personal data when using our website www.galileosat.com.
  </p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Who is the Data Controller?</h2>
  <p>The data controller is:</p>
  <p className="ml-4">
    <strong>GalileoSat</strong> <br />
    Registered office: Via Bassa Arzergrande (Pd) <br />
    Email: <a href="mailto:info@galileosat.com" className="text-blue-500 underline">info@galileosat.com</a>
  </p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">3. What Data Do We Collect?</h2>
  <h3 className="text-xl font-bold text-gray-900 mb-4">Data We Collect Directly</h3>
  <ul className="list-disc ml-6 mb-4">
    <li>Identifying data: Name, surname, email, phone number.</li>
    <li>Data provided through forms: Information collected when filling out contact or consultation request forms.</li>
  </ul>

  <h3 className="text-xl font-bold text-gray-900 mb-4">Data Collected Automatically</h3>
  <ul className="list-disc ml-6 mb-4">
    <li>Technical data: IP address, device type, operating system, browser.</li>
    <li>Cookies: Information on browsing preferences. (See our <a href="/cookie" className="text-blue-500 underline">Cookie Policy</a> for more details.)</li>
  </ul>

  <h3 className="text-xl font-bold text-gray-900 mb-4">Sensitive Data</h3>
  <p>GalileoSat does not collect sensitive data (e.g., health-related or religious beliefs) through its website.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Why Do We Collect Your Data?</h2>
  <ul className="list-disc ml-6">
    <li>To provide our services: Responding to consultation, support, and information requests.</li>
    <li>To improve our website: Analyzing traffic and optimizing user experience.</li>
    <li>For marketing purposes: Sending promotional communications only with explicit consent.</li>
    <li>To comply with legal requirements: Fulfilling legal and fiscal obligations.</li>
  </ul>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">5. On What Legal Basis Do We Process Data?</h2>
  <p>The processing of personal data is based on the following legal grounds:</p>
  <ul className="list-disc ml-6">
    <li><strong>Contract performance:</strong> When processing is necessary to provide a requested service.</li>
    <li><strong>Consent:</strong> For marketing activities or collection of non-essential data.</li>
    <li><strong>Legal obligations:</strong> When required by law (e.g., data retention for tax purposes).</li>
  </ul>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Data Sharing with Third Parties</h2>
  <p>Personal data collected may be shared with:</p>
  <ul className="list-disc ml-6">
    <li>Technical service providers: Hosting, traffic analysis (e.g., Google Analytics).</li>
    <li>Competent authorities: Only in case of legal obligations or official requests.</li>
  </ul>
  <p>Data will never be sold or shared with third parties for undisclosed purposes.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">7. How Long Do We Retain Data?</h2>
  <ul className="list-disc ml-6">
    <li><strong>Data collected to provide services:</strong> Retained for 5 years after the end of the relationship.</li>
    <li><strong>Marketing data:</strong> Retained until consent is revoked.</li>
    <li><strong>Technical and browsing data:</strong> Retained for a maximum of 12 months.</li>
  </ul>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Your Rights</h2>
  <p>In accordance with the GDPR, you have the right to:</p>
  <ul className="list-disc ml-6">
    <li>Access your personal data: Request a copy of the data we have collected.</li>
    <li>Correct your data: Request the modification of incorrect or incomplete information.</li>
    <li>Delete your data: Request the removal of personal data (right to be forgotten).</li>
    <li>Restrict processing: Under certain circumstances, you can restrict the use of your data.</li>
    <li>Withdraw consent: Revoke consent for marketing activities at any time.</li>
    <li>File a complaint: Contact the data protection authority if you believe your rights have been violated.</li>
  </ul>
  <p>To exercise your rights, send a request to <a href="mailto:info@galileosat.com" className="text-blue-500 underline">info@galileosat.com</a>.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Data Security</h2>
  <p>We use advanced technologies to ensure the security of personal data:</p>
  <ul className="list-disc ml-6">
    <li>Encryption: Protecting data during transmission.</li>
    <li>Firewalls and IDS: Protecting against unauthorized access.</li>
    <li>Limited Access: Data is accessible only to authorized personnel.</li>
  </ul>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">10. Privacy Policy Updates</h2>
  <p>
    We reserve the right to update this Privacy Policy to reflect any regulatory or operational changes.
    We encourage you to periodically review this page to stay informed.
  </p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">11. Contacts</h2>
  <p>
    If you have any questions or concerns regarding this Privacy Policy, please contact us:
  </p>
  <p>Email: <a href="mailto:info@galileosat.com" className="text-blue-500 underline">info@galileosat.com</a></p>
</section>
</div>
  </div>
   );
};

export default PrivacyPolicy;