import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const menuItems = [
  { name: 'Home', path: '/' },
  { name: 'Servizi', key: 'servizi', submenu: true },
  { name: 'Soluzioni', key: 'projects', submenu: true },
  { name: 'Blog', path: '/blog' },
  { name: 'About', path: '/about' },
  { name: 'Contatti', path: '/contact' },
];

const serviceSubmenu = [
  { name: 'IT Network', path: '/servizi/it-network', color: 'text-blue-500' },
  { name: 'Cybersecurity', path: '/servizi/cybersecurity', color: 'text-red-500' },
  { name: 'Telco IoT', path: '/servizi/telecom-iot', color: 'text-green-500' },
  { name: 'Satcom', path: '/servizi/satcom', color: 'text-yellow-500' },
  { name: 'IT Coaching', path: '/servizi/consulting', color: 'text-gray-300' },
];

const projectSubmenu = [
  { name: 'Networking', path: '/soluzioni/networking', color: 'text-blue-500' },
  { name: 'Security', path: '/soluzioni/security', color: 'text-red-500' },
  { name: 'Satellite', path: '/soluzioni/gnss', color: 'text-green-500' },
  { name: 'Programmi', path: '/soluzioni/programmi', color: 'text-yellow-500' },
];

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null); // Stato per i sottomenu desktop
  const [submenuTimeout, setSubmenuTimeout] = useState(null); // Timer per chiusura ritardata del sottomenu
  const [openMobileMenu, setOpenMobileMenu] = useState(false); // Stato per il menu mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Controllo modalità mobile
  const location = useLocation();

  // Effetto per monitorare la larghezza dello schermo
  useEffect(() => {
    const handleResize = () => {
      const isNowMobile = window.innerWidth < 768;
      setIsMobile(isNowMobile);
      if (!isNowMobile) {
        setOpenMobileMenu(false); // Chiudi il menu mobile in modalità desktop
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Funzione per gestire il clic sull'hamburger
  const toggleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-primary bg-opacity-50 backdrop-blur z-50 shadow-md">
      <div className="w-full flex items-center justify-between px-5 py-5">
        {/* Logo */}
        <div>
          <Link to="/">
            <img
              src={require('../assets/images/logo.png')}
              alt="GalileoSat Logo"
              className="h-15 w-auto"
            />
          </Link>
        </div>

        {/* Pulsante Hamburger */}
        {isMobile && (
          <button
            className="absolute right-5 md:hidden text-secondary"
            onClick={toggleMobileMenu}
          >
            {openMobileMenu ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
          </button>
        )}

        {/* Menu Mobile */}
        {openMobileMenu && isMobile && (
          <div className="absolute top-full left-0 w-full bg-[#070918] text-white z-40 shadow-lg">
            <ul className="flex flex-col space-y-4 py-4 px-6">
              {menuItems.map((item) => (
                <li key={item.name} className="w-full">
                  {item.submenu ? (
                    <>
                      <button
                        className="block w-full text-lg text-gray-300 hover:text-yellow-500 text-left"
                        onClick={() =>
                          setOpenSubmenu((prev) => (prev === item.key ? null : item.key))
                        }
                      >
                        {item.name}
                      </button>
                      {openSubmenu === item.key && (
                        <ul className="pl-5 mt-2 space-y-2">
                          {(item.key === 'services' ? serviceSubmenu : projectSubmenu).map(
                            (submenuItem) => (
                              <li key={submenuItem.name}>
                                <Link
                                  to={submenuItem.path}
                                  className="block text-base text-gray-400 hover:text-yellow-500"
                                  onClick={() => setOpenMobileMenu(false)}
                                >
                                  {submenuItem.name}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </>
                  ) : (
                    <Link
                      to={item.path}
                      className="block w-full text-lg text-gray-300 hover:text-yellow-500 text-left"
                      onClick={() => setOpenMobileMenu(false)}
                    >
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Navigazione Desktop */}
        {!isMobile && (
          <nav className="hidden md:flex flex-1 items-center space-x-20 ml-48">
            {menuItems.map((item) =>
              item.submenu ? (
                <div
                  key={item.name}
                  className="relative group"
                  onMouseEnter={() => {
                    if (submenuTimeout) clearTimeout(submenuTimeout);
                    setOpenSubmenu(item.key);
                  }}
                  onMouseLeave={() => {
                    const timeout = setTimeout(() => setOpenSubmenu(null), 300);
                    setSubmenuTimeout(timeout);
                  }}
                >
                  <button
                    className={`text-secondary text-xl hover:text-yellow-500 cursor-default flex items-center ${
                      location.pathname.includes(item.path) ? 'font-bold text-yellow-500' : ''
                    }`}
                  >
                    {item.name} <span className="ml-1">▾</span>
                  </button>
                  {openSubmenu === item.key && (
                    <div className="absolute top-full left-0 bg-[#1e272e] text-secondary rounded shadow-lg mt-2 z-40">
                      {(item.key === 'servizi' ? serviceSubmenu : projectSubmenu).map(
                        (submenuItem) => (
                          <Link
                            key={submenuItem.name}
                            to={submenuItem.path}
                            className="block px-4 py-2 text-gray-300 hover:text-blue-500 hover:bg-gray-800"
                          >
                            {submenuItem.name}
                          </Link>
                        )
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`text-secondary text-xl hover:text-yellow-500 ${
                    location.pathname === item.path ? 'font-bold text-yellow-500' : ''
                  }`}
                >
                  {item.name}
                </Link>
              )
            )}
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
