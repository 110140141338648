import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import FooterMap from './FooterMap';

const Footer = () => {
  return (
    <footer className="bg-[#070918] text-gray-300 py-10">
      {/* Sezione superiore */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 px-4">
        {/* Colonna 1: Chi siamo */}
        <div>
          <h3 className="text-xl font-bold mb-4 text-white">Chi siamo</h3>
          <p
            className="text-sm"
            style={{
              lineHeight: '2.25',
              marginBottom: '10px',
            }}
          >
            GalileoSat è specializzata in soluzioni di Cybersecurity, IT, Intelligenza Artificiale e tecnologie satellitari.
            Offriamo consulenza, formazione e servizi personalizzati per supportare le aziende nel raggiungere i loro obiettivi tecnologici.
          </p>
          <div className="mt-4">
            <a
              href="/about"
              className="text-blue-500 hover:underline font-bold"
            >
              Scopri di più
            </a>
          </div>
        </div>

        {/* Colonna 2: Link rapidi */}
        <div>
          <h3 className="text-xl font-bold mb-4 text-white">Link rapidi</h3>
          <ul className="space-y-2">
            <li><a href="/" className="hover:text-blue-500">Home</a></li>
            <li><a href="/servizi" className="hover:text-blue-500">Servizi</a></li>
            <li><a href="/soluzioni" className="hover:text-blue-500">Soluzioni</a></li>
            <li><a href="/contact" className="hover:text-blue-500">Contatti</a></li>
          </ul>
        </div>

        {/* Colonna 3: Sottomenu Servizi e Soluzioni */}
        <div>
          <h3 className="text-xl font-bold mb-4 text-white">Approfondimenti</h3>
          <ul className="space-y-2">
            <li><a href="/servizi/it-network" className="hover:text-blue-500">IT Network</a></li>
            <li><a href="/servizi/cybersecurity" className="hover:text-blue-500">Cybersecurity</a></li>
            <li><a href="/servizi/telecom-iot" className="hover:text-blue-500">Telco / IoT</a></li>
            <li><a href="/servizi/satcom" className="hover:text-blue-500">Satcom</a></li>
            <li><a href="/servizi/consulting" className="hover:text-blue-500">IT Consulting</a></li>
            <li><a href="/soluzioni/networking" className="hover:text-blue-500">Networking</a></li>
            <li><a href="/soluzioni/security" className="hover:text-blue-500">IT Security</a></li>
            <li><a href="/soluzioni/gnss" className="hover:text-blue-500">GNSS / Satelliti</a></li>
            <li><a href="/soluzioni/programmi" className="hover:text-blue-500">Programmi Coaching</a></li>
          </ul>
        </div>

        {/* Colonna 4: Contatti */}
        <div>
          <h3 className="text-xl font-bold mb-4 text-white">Contatti</h3>
          <ul className="space-y-2">
            <li>Email: <a href="mailto:info@galileosat.com" className="hover:text-blue-500">info@galileosat.com</a></li>
            <li>Indirizzo: <span className="hover:text-blue-500">Arzergrande (Padova)</span></li>
          </ul>
          {/* Mappa di Mapbox nel footer */}
<div className="map-container mt-6 mb-8">
  <FooterMap />
</div>
        </div>
      </div>

      {/* Sezione inferiore */}
      <div className="mt-10 py-4 border-t border-gray-700">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4 text-sm text-center md:text-left">
          {/* Colonna 1: Icone social */}
          <div className="flex justify-center md:justify-start space-x-4">
            <a href="https://facebook.com" aria-label="Facebook" className="hover:text-blue-500">
              <FontAwesomeIcon icon={faFacebook} size="lg" />
            </a>
            <a href="https://x.com" aria-label="Twitter" className="hover:text-blue-500">
              <FontAwesomeIcon icon={faXTwitter} size="lg" />
            </a>
            <a href="https://linkedin.com" aria-label="LinkedIn" className="hover:text-blue-500">
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
          </div>

          {/* Colonna 2: Copyright */}
          <div className="text-center md:text-left text-gray-400">
            © 2025 GalileoSat. Tutti i diritti riservati.
          </div>

          {/* Colonna 3: Cookie, Privacy e Terms */}
          <div className="flex justify-center md:justify-end space-x-4">
            <a href="/cookie" className="hover:text-blue-500">Cookie</a>
            <a href="/privacy" className="hover:text-blue-500">Privacy</a>
            <a href="/terms" className="hover:text-blue-500">Terms of Use</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
