import React from 'react';
import RJ45Image from '../../assets/images/RJ45.webp';
import ProxmoxLogo from '../../assets/icons/proxmox.svg';
import AwsLogo from '../../assets/icons/aws.svg';
import MicrosoftLogo from '../../assets/icons/microsoft.svg';
import CiscoLogo from '../../assets/icons/cisco.svg';
import HpLogo from '../../assets/icons/hp.svg';
import IbmLogo from '../../assets/icons/ibm.svg';
import { FaCheck } from 'react-icons/fa';

const Networking = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#070918] to-[#111827] text-gray-300">
      {/* Blocco Hero con Overlay */}
      <section
        className="relative bg-cover bg-center h-[600px] flex items-center justify-center text-center"
        style={{
          backgroundImage: `url(${RJ45Image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-900/80"></div>
        {/* Testo sopra l'overlay */}
        <div className="relative z-10 text-white px-6 mb-0">
          <h1 className="text-4xl font-extrabold mb-20">Networking: Trasformiamo la Tua Rete in un Asset Strategico</h1>
          <p className="text-lg md:text-2xl mb-8">
            Ottimizziamo la tua rete per garantire performance, sicurezza e scalabilità.
          </p>
          <div className="mt-28"></div>
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
        </div>
      </section>

      {/* Sezione Il Nostro Processo */}
      <section className="py-20 bg-gray-100 text-gray-800">
        <div className="max-w-7xl mx-auto text-center px-6 md:px-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-blue-900">Il Nostro Approccio al Networking</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Passaggio 1 */}
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-blue-700 mb-5 text-center">1. Analisi delle Esigenze</h3>
              <p className="text-gray-700 text-left">
                Identifichiamo i punti critici e le opportunità nella tua rete attraverso un'analisi approfondita.
              </p>
            </div>
            {/* Passaggio 2 */}
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-blue-700 mb-4 text-center">2. Progettazione Personalizzata</h3>
              <p className="text-gray-700 text-left">
                Creiamo un piano su misura per ottimizzare la rete, tenendo conto di sicurezza, performance e scalabilità.
              </p>
            </div>
            {/* Passaggio 3 */}
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-blue-700 mb-4 text-center">3. Implementazione Precisa</h3>
              <p className="text-gray-700 text-left">
                Realizziamo la rete con le migliori tecnologie disponibili, garantendo una transizione senza interruzioni.
              </p>
            </div>
            {/* Passaggio 4 */}
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-blue-700 mb-4 text-center">4. Monitoraggio Continuo</h3>
              <p className="text-gray-700 text-left">
                Forniamo monitoraggio continuo e interventi proattivi per assicurare prestazioni costanti nel tempo.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center mt-16">
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
        </div>
      </section>

      {/* Sezione Case Study */}
      <section className="py-20 bg-gray-900 text-gray-300">
        <div className="max-w-6xl mx-auto px-6 md:px-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-yellow-500 text-center">
            Case Study: Migrazione di una Rete Aziendale per Ottimizzare Sicurezza e Prestazioni
          </h2>
          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-bold text-red-600 mb-4">La Sfida</h3>
              <p className="text-gray-300">
                Un'azienda leader nel settore Logistico si trovava ad affrontare sfide critiche a causa di una rete tecnologicamente superata. Frequenti interruzioni operative e vulnerabilità di sicurezza compromettevano la produttività e mettevano a rischio dati sensibili, fondamentali per il successo aziendale.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-green-500 mb-4">La Soluzione</h3>
              <p className="text-gray-300">
                Abbiamo intrapreso un percorso strategico, partendo da un’analisi dettagliata delle esigenze e delle criticità aziendali. Abbiamo progettato e implementato una rete avanzata, integrando:
              </p>
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                <li>VLAN ottimizzate per garantire una distribuzione equilibrata del traffico.</li>
                <li>Firewall di ultima generazione per una protezione robusta e proattiva contro le minacce.</li>
                <li>Backup automatici con ridondanze a caldo, assicurando la continuità operativa anche in caso di guasti.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-green-500 mb-4">I Risultati</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-400">
                <li>70% di riduzione dei downtime, garantendo maggiore affidabilità.</li>
                <li>40% di miglioramento delle prestazioni della rete, ottimizzando i processi operativi.</li>
                <li>Sicurezza al massimo livello, con zero incidenti registrati nei primi 12 mesi.</li>
              </ul>
              <p className="text-gray-300 mt-4">
                Questi risultati hanno consolidato la competitività dell’azienda, trasformando una criticità in un punto di forza strategico.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center mt-16">
          <a
            href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
          >
            Contattaci Ora
          </a>
        </div>
      </section>

      {/* Soluzioni Tecniche Specifiche */}
      <section className="py-20 bg-gray-100 text-gray-800">
  <div className="max-w-7xl mx-auto px-6 md:px-12 text-center">
    <h2 className="text-3xl md:text-4xl font-bold mb-12 text-blue-800">
      Gli Strumenti che Utilizziamo per Ottenere Risultati
    </h2>
    <p className="text-lg md:text-xl text-gray-700 mb-12">
      Per garantire soluzioni efficaci, utilizziamo tecnologie avanzate e metodologie collaudate.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {/* Tool 1 */}
      <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
        <h3 className="text-xl font-bold text-blue-700 mb-4">Firewall Avanzati</h3>
        <p className="text-gray-700">
          Utilizziamo soluzioni di sicurezza come Palo Alto, Fortinet e PfSense per proteggere la tua rete.
        </p>
      </div>
      {/* Tool 2 */}
      <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
        <h3 className="text-xl font-bold text-blue-700 mb-4">Configurazione VLAN</h3>
        <p className="text-gray-700">
          Ottimizziamo il bilanciamento del traffico tramite configurazioni avanzate di VLAN.
        </p>
      </div>
      {/* Tool 3 */}
      <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
        <h3 className="text-xl font-bold text-blue-700 mb-4">Monitoraggio Continuo</h3>
        <p className="text-gray-700">
          Assicuriamo prestazioni costanti con strumenti professionali di monitoraggio della rete.
        </p>
      </div>
    </div>

    {/* Fila di Loghi */}
    <hr className="my-16 border-t border-gray-400" />
    <div className="mt-16 grid grid-cols-2 md:grid-cols-4 gap-8 items-center">
    <img src={ProxmoxLogo} alt="Proxmox" className="h-16 mx-auto" />
      <img src="/logos/pfsense.png" alt="PfSense" className="h-18 mx-auto" />
      <img src={AwsLogo} alt="Aws" className="h-16 mx-auto" />
      <img src={MicrosoftLogo} alt="Microsoft" className="h-16 mx-auto" />
      <img src={CiscoLogo} alt="Cisco" className="h-16 mx-auto" />
      <img src={HpLogo} alt="Hp" className="h-16 mx-auto" />
      <img src={IbmLogo} alt="Ibm" className="h-16 mx-auto" />
    </div>
    <p className="text-sm text-gray-500 mt-12 text-center">
      I loghi mostrati rappresentano strumenti e tecnologie utilizzate in progetti reali. Non implicano accordi commerciali o partnership ufficiali.
    </p>
  </div>
</section>
      
      {/* Vantaggi per il Cliente e CTA Finale */}
      <section className="py-20 bg-gray-900 text-gray-300">
        <div className="max-w-6xl mx-auto px-6 md:px-12 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-yellow-500">I Benefici delle Nostre Soluzioni di Networking</h2>
          <ul className="space-y-4 text-gray-400 text-left md:text-center">
            <li className="flex items-center gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="font-bold text-white">   Efficienza Operativa:</span> Reti performanti per supportare i carichi di lavoro.</span>
            </li>
            <li className="flex items-center gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="font-bold text-white">   Sicurezza Garantita:</span> Protezione da attacchi ed accessi non autorizzati.</span>
            </li>
            <li className="flex items-center gap-2">
              <FaCheck className="text-green-500" />
              <span><span className="font-bold text-white">   Scalabilità:</span> Infrastrutture pronte a crescere con le tue esigenze aziendali.</span>
            </li>
          </ul>
          <div className="mt-20">
            <h3 className="text-2xl font-bold text-yellow-500 mb-4">Pronto a Trasformare la Tua Rete?</h3>
            <p className="text-lg text-gray-400 mb-16">Siamo pronti a progettare la soluzione perfetta per le tue esigenze di networking.</p>
            <a
              href="/contact"
            className="bg-secondary text-primary px-6 py-3 rounded-lg hover:bg-gray-400 transition font-bold"
            >
              Richiedi una Consulenza Gratuita
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Networking;