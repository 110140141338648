import React from 'react';
import { useNavigate } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#070918] py-12">
      {/* Titolo della pagina */}
      <h1 className="text-4xl font-bold text-center text-yellow-500 mb-10">
        Chi Siamo: GalileoSat
      </h1>
      {/* Contenuto della pagina */}
      <div className="max-w-4xl mx-auto px-6 text-gray-300 leading-relaxed">
        <p className="mb-8">
        GalileoSat è una realtà nata dalla passione per la tecnologia, l'innovazione e la sicurezza informatica. Siamo specializzati in Cybersecurity, IT, Intelligenza Artificiale e Soluzioni Satellitari, con un focus dedicato alle esigenze delle piccole e medie imprese (PMI).
        </p>

        <h2 className="text-2xl font-bold text-white mb-6">La nostra missione</h2>
        <p className="mb-8">
        La nostra missione è fornire soluzioni tecnologiche avanzate e personalizzate, aiutando le aziende a prosperare in un mondo digitale in rapida evoluzione. Offriamo consulenza, formazione e implementazione per proteggere i dati, ottimizzare i processi e supportare i clienti nell'affrontare le sfide tecnologiche.
        </p>

        <h2 className="text-2xl font-bold text-white mb-6">Una storia di innovazione</h2>
        <p className="mb-8">
        Con solide radici in tecnologie avanzate e una visione orientata al futuro, GalileoSat punta a creare un impatto significativo nel settore IT. L’esperienza del nostro team spazia dalla gestione di reti aziendali alla sicurezza informatica, fino alle tecnologie satellitari GNSS per la logistica e il monitoraggio remoto. Ci dedichiamo allo sviluppo di soluzioni innovative per settori critici come logistica, produzione industriale, costruzioni e oltre.
        </p>
        
        <h2 className="text-2xl font-bold text-white mb-6">Cosa facciamo</h2>
        <ul className="list-disc list-inside mb-8">
          <li className="mb-4">
            <strong>Cybersecurity:</strong> Configuriamo reti sicure, gestiamo VPN e VLAN, implementiamo soluzioni di sicurezza su misura per proteggere i dati aziendali.
          </li>
          <li className="mb-4">
            <strong>Soluzioni IT:</strong> Offriamo supporto tecnico, ottimizzazione delle infrastrutture di rete e consulenza per la gestione IT.
          </li>
          <li className="mb-4">
            <strong>Intelligenza Artificiale:</strong> Forniamo soluzioni AI per ottimizzare processi, migliorare la sicurezza e ridurre i costi operativi.
          </li>
          <li className="mb-4">
            <strong>Soluzioni Satellitari:</strong> Utilizziamo tecnologie GNSS per la gestione ed il monitoraggio delle risorse in tempo reale.
          </li>
          <li>
            <strong>Formazione:</strong> Organizziamo percorsi di sensibilizzazione e formazione sui rischi informatici, normative come il GDPR e l’adozione di tecnologie emergenti.
          </li>
        </ul>

        <h2 className="text-2xl font-bold text-white mb-6">I nostri valori</h2>
        <ul className="list-disc list-inside mb-8">
          <li className="mb-4">
            <strong>Innovazione continua:</strong> Cerchiamo costantemente nuove soluzioni per migliorare i risultati dei nostri clienti.
          </li>
          <li className="mb-4">
            <strong>Affidabilità:</strong> Offriamo servizi sicuri e performanti per costruire relazioni di fiducia.
          </li>
          <li className="mb-4">
            <strong>Personalizzazione:</strong> Progettiamo soluzioni su misura, ascoltando e comprendendo le esigenze specifiche dei clienti.
          </li>
          <li>
            <strong>Formazione e consapevolezza:</strong> Educare il personale aziendale è il nostro contributo per rafforzare la sicurezza organizzativa.
          </li>
        </ul>

        <h2 className="text-2xl font-bold text-white mb-6">Perché GalileoSat</h2>
        <p className="mb-8">
        La nostra forza risiede nella capacità di risolvere concretamente le sfide aziendali con competenza tecnica e approccio pratico. Ogni progetto è sviluppato per soddisfare esigenze specifiche, garantendo risultati misurabili e un supporto costante.
        </p>
        <p className="mb-8">
        Protezione dei sistemi aziendali, ottimizzazione delle reti, esplorazione di nuove tecnologie: qualunque sia la tua esigenza, GalileoSat è il tuo partner di fiducia.
        </p>
        <p>
        Scopri di più sui nostri progetti e servizi o contattaci per una consulenza personalizzata. Il futuro è digitale, costruiamolo insieme.
        </p>

        {/* Pulsante CTA */}
        <div className="text-center mt-12">
          <button
            onClick={() => navigate('/contact')}
            className="bg-transparent border border-secondary text-secondary px-6 py-3 rounded-lg hover:bg-secondary hover:text-primary transition font-bold"
          >
            Contattaci Ora
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;