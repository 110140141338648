import React from 'react';
import { Link } from 'react-router-dom';

const Soluzioni = () => {
  const solutions = [
    {
      title: "Networking",
      description: "Progettazione e gestione di reti aziendali.",
      link: "/soluzioni/networking",
    },
    {
      title: "Sicurezza IT",
      description: "Protezione avanzata per la tua infrastruttura.",
      link: "/soluzioni/security",
    },
    {
      title: "GNSS / Satelliti",
      description: "Tracciamento e monitoraggio delle risorse.",
      link: "/soluzioni/gnss",
    },
    {
      title: "Software e Programmi",
      description: "Soluzioni software personalizzate per le aziende.",
      link: "/soluzioni/programmi",
    },
  ];

  return (
    <div className="min-h-screen bg-[#070918] py-12">
      <h1 className="text-4xl font-bold text-center text-yellow-500 mb-10">
        Le nostre Soluzioni
      </h1>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-6">
        {solutions.map((solution, index) => (
          <div key={index} className="bg-[#1c1c1c] p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold text-white mb-4">{solution.title}</h2>
            <p className="text-gray-300 mb-6">{solution.description}</p>
            <Link
              to={solution.link}
              className="text-yellow-500 font-bold hover:text-yellow-400 transition"
            >
              Scopri di più →
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Soluzioni;

