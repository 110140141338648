import React, { useState } from 'react';

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState(''); // Stato per la categoria selezionata

  const articles = [
    {
      id: 1,
      date: "20 Dicembre 2024",
      title: "5 segnali di un attacco ransomware imminente e come difendersi",
      category: "Cybersecurity",
      image: "/Blog-Img/CyberPMI.webp",
      excerpt:
        "Scopri i 5 segnali più comuni di un attacco ransomware imminente e come proteggere i tuoi sistemi aziendali da questa crescente minaccia informatica.",
      link: "/blog/cybersecurity-guide",
    },
    {
      id: 2,
      date: "15 Dicembre 2024",
      title: "VPN aziendali: come proteggere le comunicazioni remote",
      category: "Cybersecurity",
      image: "/Blog-Img/VPN.webp",
      excerpt:
        "Le VPN sono essenziali per proteggere le comunicazioni aziendali. Scopri come implementarle correttamente e i vantaggi per la tua sicurezza.",
      link: "/blog/vpn-guide",
    },
    {
      id: 3,
      date: "10 Dicembre 2024",
      title: "Come rispettare il GDPR: guida per PMI",
      category: "Normative IT e Compliance",
      image: "/Blog-Img/GDPR.webp",
      excerpt:
        "Scopri come le PMI possono rispettare il GDPR, garantendo conformità normativa e protezione dei dati personali.",
      link: "/blog/gdpr-guide",
    },
  {
    id: 4,
    date: "5 Dicembre 2024",
    title: "AI Act: una nuova era per l’Intelligenza Artificiale in Europa", 
    category: "Normative IT e Compliance",
    image: "/Blog-Img/AiAct.webp", 
    excerpt:
      "Scopri come il nuovo regolamento europeo sull’Intelligenza Artificiale definisce standard di sicurezza e trasparenza per l’uso dell’IA.", 
      link: "/blog/ai-act-guide", 
  },
  {
    id: 5,
    date: "1 Dicembre 2024",
    title: "Bilanciamento del carico: come migliorare le prestazioni della tua rete", 
    category: "Networking",
    image: "/Blog-Img/Switch.webp", 
    excerpt:
      "Scopri come il bilanciamento del carico può ottimizzare le prestazioni della tua rete aziendale, riducendo i colli di bottiglia e migliorando l'affidabilità.", 
      link: "/blog/load-balancing-guide", 
  },
  {
    id: 6,
    date: "25 Novembre 2024",
    title: "VLAN: perché sono essenziali per la sicurezza e l’efficienza", 
    category: "Networking",
    image: "/Blog-Img/VLAN.webp", 
    excerpt:
      "Le VLAN migliorano la sicurezza e l'efficienza delle reti aziendali. Scopri come segmentare il traffico per ottimizzare le prestazioni e ridurre i rischi.", 
      link: "/blog/vlan-guide", 
  },
  {
    id: 7,
    date: "15 Novembre 2024",
    title: "Come il monitoraggio satellitare rivoluziona la catena del freddo",
    category: "Soluzioni Satellitari",
    image: "/Blog-Img/TruckTir.webp", 
    excerpt:
      "Scopri come il monitoraggio satellitare garantisce il controllo della temperatura nella catena del freddo, preservando la qualità dei prodotti.", 
      link: "/blog/catena-del-freddo-guide", 
  },
  {
    id: 8,
    date: "10 Novembre 2024",
    title: "GNSS per la logistica: ottimizzare il tracciamento delle risorse", 
    category: "Soluzioni Satellitari",
    image: "/Blog-Img/GPS.webp", 
    excerpt:
      "Ottimizza la gestione della logistica con le tecnologie GNSS: scopri come migliorare il tracciamento delle risorse e ridurre i costi.", 
      link: "/blog/gnss-logistic-guide", 
  },
  {
    id: 9,
    date: "5 Novembre 2024",
    title: "L’AI nella cybersecurity: opportunità e rischi", 
    category: "Intelligenza Artificiale e Innovazione Tecnologica",
    image: "/Blog-Img/AiAct-1.webp", 
    excerpt:
      "Scopri come l’intelligenza artificiale sta rivoluzionando la cybersecurity, aumentando la protezione e affrontando nuove sfide.", 
      link: "/blog/ai-cybersecurity-guide", 
  },
  {
    id: 10,
    date: "1 Novembre 2024",
    title: "Automazione IT: come l’AI può ridurre i costi operativi", 
    category: "Intelligenza Artificiale e Innovazione Tecnologica",
    image: "/Blog-Img/Industry.webp", 
    excerpt:
      "Scopri come l’intelligenza artificiale può automatizzare i processi IT, ottimizzando le operazioni aziendali e riducendo le spese.", 
      link: "/blog/automazione-it-guide", 
  },
  {
    id: 11,
    date: "25 Ottobre 2024",
    title: "Cybersecurity Awareness: come preparare il tuo team",
    category: "Formazione e Coaching",
    image: "/Blog-Img/Training.webp", 
    excerpt:
      "Scopri come sensibilizzare il tuo team sui rischi informatici e migliorare la sicurezza aziendale con programmi di formazione efficaci.", 
    link: "/blog/cybersecurity-awareness-guide", 
  },
  {
    id: 12,
    date: "15 Ottobre 2024",
    title: "GDPR per manager: le 5 cose essenziali da sapere",
    category: "Formazione e Coaching",
    image: "/Blog-Img/GDPRmanager.webp", 
    excerpt:
      "Scopri i punti chiave del GDPR che ogni manager deve conoscere per garantire la conformità normativa e proteggere i dati aziendali.", 
      link: "/blog/gdpr-manager-guide", 
  },
  {
    id: 13,
    date: "10 Ottobre 2024",
    title: "Case Study: come abbiamo ottimizzato la rete di un’azienda manifatturiera",
    category: "Case Study e Success Stories",
    image: "/Blog-Img/Manifattura.webp", 
    excerpt:
      "Scopri come un’azienda manifatturiera ha migliorato l’efficienza e ridotto i tempi di inattività grazie a un’ottimizzazione della rete personalizzata.", 
      link: "/blog/case-study-manifattura-guide", 
  },
  {
    id: 14,
    date: "5 Ottobre 2024",
    title: "Ridurre il downtime del 60%: una storia di successo nel settore ICT", 
    category: "Case Study e Success Stories",
    image: "/Blog-Img/ICT.webp", 
    excerpt:
      "Scopri come abbiamo aiutato un’azienda ICT a ridurre il downtime del 60% e migliorare l’affidabilità dei suoi servizi.", 
      link: "/blog/downtime-ict-guide", 
  },
  ];

  const categories = [
    { id: 1, name: "Cybersecurity" },
    { id: 2, name: "Normative IT e Compliance" },
    { id: 3, name: "Networking" },
    { id: 4, name: "Soluzioni Satellitari" },
    { id: 5, name: "Intelligenza Artificiale e Innovazione Tecnologica" },
    { id: 6, name: "Formazione e Coaching" },
    { id: 7, name: "Case Study e Success Stories" },
  ];

  // Filtro per articoli basato sulla categoria selezionata
  const filteredArticles = selectedCategory
    ? articles.filter((article) => article.category === selectedCategory)
    : articles;


    return (
      <div className="blog-page flex flex-col gap-10 p-6">
        {/* Titolo e Sottotitolo */}
        <header className="text-center mt-12 mb-12">
          <h1 className="text-yellow-500 text-4xl font-bold leading-relaxed">
            GalileoSat Blog
          </h1>
          <p className="text-white text-lg mt-4 leading-loose">
            Conoscenza Digitale: Idee e Soluzioni per l'IT del Futuro
          </p>
        </header>
  
        <div className="flex flex-wrap gap-10">
          {/* Card Aree Tematiche */}
          <div className="blog-categories-card bg-transparent text-white p-4 rounded-lg w-full md:w-1/3">
            <h2 className="text-xl font-bold mb-4">Aree Tematiche</h2>
            <ul>
              <li
                className={`mb-2 cursor-pointer ${
                  !selectedCategory ? 'text-yellow-500' : 'text-gray-300'
                }`}
                onClick={() => setSelectedCategory('')}
              >
                Tutte le categorie
              </li>
              {categories.map((category) => (
                <li
                  key={category.id}
                  className={`mb-2 cursor-pointer ${
                    selectedCategory === category.name ? 'text-yellow-500' : 'text-gray-300'
                  } hover:text-yellow-500`}
                  onClick={() => setSelectedCategory(category.name)}
                >
                  {category.name}
                </li>
              ))}
            </ul>
          </div>
  
          {/* Card Articoli */}
          <div className="blog-content flex-1 grid grid-cols-1 gap-8">
            {filteredArticles.map((article) => (
              <a
                key={article.id}
                href={article.link}
                className="blog-card block bg-transparent p-4 rounded-lg hover:bg-gray-custom transition"
              >
                {/* Data */}
                <span className="blog-card-date text-gray-400 text-sm block mb-4">
                  {article.date}
                </span>
                {/* Immagine */}
                <div className="blog-card-image mb-4">
                  <img
                    src={article.image}
                    alt={article.title}
                    className="w-full h-60 object-cover rounded-lg"
                  />
                </div>
                {/* Titolo */}
                <h2 className="blog-card-title text-lg font-bold text-center mb-2">
                  {article.title}
                </h2>
                {/* Estratto */}
                <p className="blog-card-excerpt text-gray-300">{article.excerpt}</p>
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default Blog;